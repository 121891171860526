import {motion} from "framer-motion";
import Menu from "../components/menu/Menu";
import React from "react";
import Footer from "../components/footer/Footer";
import {useMediaQuery} from "@material-ui/core";
import theme from "../theme";

const InternReglementPage = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div className={"Page-root"}>
            <motion.section exit={{opacity: 0}}>
                <Menu/>
                <section className={isMobile ? "InternReglementPage-section1-mobile" : "InternReglementPage-section1"}>
                    <div
                        className={isMobile ? "InternReglementPage-textBox-right-mobile" : "InternReglementPage-textBox-right"}>
                        <h1 className={"Typography-red"}>Intern reglement</h1>

                        <h2 className={"Typography-red"}>Artikel 1. Het Intern reglement</h2>

                        <p className={"Typography-red"}>
                            1.1. Het Intern reglement van de Diana VZW dient ter aanvulling van de statuten, en dient
                            net zoals de statuten bekend te worden gemaakt op de website van de VZW.
                        </p>


                    </div>

                </section>

                <section className={isMobile ? "InternReglementPage-section4-mobile" : "InternReglementPage-section4"}>
                    <div
                        className={isMobile ? "InternReglementPage-textBox-right-mobile" : "InternReglementPage-textBox-right"}>

                        <h2 className={"Typography-white"}>Artikel 2. Facbar</h2>

                        <p className={"Typography-white"}>
                            Op moment van schrijven, zijnde 14 februari 2022, is Diana's facbar gelegen in 't Archief,
                            Zeelstraat 1, 3000 Leuven
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            2.2. Studentenvereniging Diana stelt een verantwoordelijke aan voor het beheer van Diana’s
                            facbar-activiteiten en de contacten met de café-uitbater. Deze verantwoordelijke wordt
                            telkens aangeduid door de opkomende praeses.
                        </p>
                    </div>
                </section>


                <section className={isMobile ? "InternReglementPage-section3-mobile" : "InternReglementPage-section3"}>
                    <div
                        className={isMobile ? "InternReglementPage-textBox-right-mobile" : "InternReglementPage-textBox-right"}>

                        <h2 className={"Typography-red"}>Artikel 3. Verbonden organisaties</h2>

                        <p className={"Typography-red"}>
                            3.1. Studentenvereniging Diana is onderdeel van de VZW OKeR. Dit is de overkoepelende
                            vereniging die de kringen van de University College Leuven Limburg met elkaar verbindt.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            3.2. Het Diana praesidium kan verbintenissen aangaan met andere kringen en clubs voor de
                            duur van één praesidiumjaar. Deze andere organisaties mogen geen negatieve of politieke
                            weerslag hebben op Diana, of strijdig zijn met andere bepalingen in de statuten.
                        </p>
                    </div>

                </section>


                <section className={isMobile ? "InternReglementPage-section4-mobile" : "InternReglementPage-section4"}>
                    <div
                        className={isMobile ? "InternReglementPage-section4-box-mobile" : "InternReglementPage-section4-box"}>
                        <div
                            className={isMobile ? "InternReglementPage-textBox-right-mobile" : "InternReglementPage-textBox-right"}>
                            <h2 className={"Typography-white"}>Artikel 4. Verkiezingen</h2>

                            <p className={"Typography-white Typography-alinea"}>
                                4.1 Aanvullend op Titel VI van de statuten worden hieronder de regels van kiesweek,
                                kiescomité, kiesploeg, lolploeg(en) en de stemming verder besproken.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.2. De kiesweek wordt conform de statuten elk academiejaar georganiseerd tussen 1 april
                                en 15 mei. Het kiescomité (zie verder) zal tijdig bekend maken tijdens welke week de
                                kiesweek valt.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.3. De kiesweek, en bijgevolg ook de promotiecampagne die hierbij hoort, mag alleen
                                lopen van zondag op maandag 00:00 uur tot donderdagnamiddag 16:00 uur. Buiten deze
                                periode mag er geen promotie worden gemaakt door de kiesploeg, met uitzondering van de
                                niet-kiescantus.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.4. Op zondagavond van de kiesweek, om middernacht, stellen de ploegen zich voor in de
                                huidige facbar van Diana. Het voorstellen van de kiesploeg dient verplicht gepaard te
                                gaan met het geven van een vat bier van minimum 50 liter. Lolploegen worden hiervan
                                ontzien (zie verder). Als door omstandigheden dit niet kan doorgaan zal het vat bier op
                                een later moment van het bestuursjaar gegeven worden.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.5. Het kiesreglement zijn regels van zaken die niet mogen, daarom mogen andere dingen
                                niet automatisch wel. In het reglement zullen ook de verwachtingen van de kiesweek
                                vermeld worden.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.6. Het kiesreglement mag niet meer aangepast worden 2 weken voor de kiesweek begint.
                                Het kiescomité deelt het kiesreglement mee aan de opkomende kiesploeg tussen 18 maart en
                                31 maart.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.7. Wanneer er fouten tegen het reglement gemaakt worden door het kiescomité, kunnen de
                                ploegen niet gesanctioneerd worden voor die fouten.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.8. Wanneer bepaalde regels niet haalbaar zijn, kan de kiesploeg vragen dat de regels
                                versoepeld worden om het praesidium te behouden. Dit gebeurt dan in samenspraak met het
                                kiescomité.
                            </p>
                            <p className={"Typography-white Typography-alinea Typography-bold"}>
                                Het kiescomité
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.9. Het goede en eerlijke verloop van de verkiezingen wordt verzorgd door het
                                kiescomité. Dit orgaan bestaat uit minimum 1 en maximum 3 effectieve leden, die zich
                                kenbaar dienen te maken op tenminste de laatste Algemene Vergadering die bijeen wordt
                                geroepen voor de paasvakantie.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.10. Het kiescomité dient goedgekeurd te worden door de Algemene Vergadering met een
                                meerderheid. Bij problemen met de vorming van het kiescomité wordt de VZW OKeR hiervoor
                                geraadpleegd.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.11. Het kiescomité ziet erop toe dat de bepalingen van het kiesreglement worden
                                nageleefd en kan sancties opleggen bij gemaakte fouten tegen dit kiesreglement. Tevens
                                maakt het kiescomité reclame voor de kiesweek op Campus Gasthuisberg en Campus Proximus
                                en bij de VZW OKeR.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.12. Op de activiteiten van de ploegen verwacht het kiescomité als eerste bedient te
                                worden of onmiddellijk wanneer nodig.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.13. Bij het herhaaldelijk discussiëren met het kiescomité zullen er extra strafpunten
                                uitgedeeld worden aan de betrokken kiesploeg.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.14. Bij het niet uitvoeren van een aangekondigde activiteit zal het kiescomité de
                                kiesploeg bestraffen met een onbepaald aantal strafpunten naarmate de omvang van de
                                activiteit en de goesting die het kiescomité had om naar deze activiteit af te komen.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.15. Het aantal strafpunten dat de kiesploeg heeft, zal ten vroegste pas bekend worden
                                gemaakt bij de telling van de stemmen op de kiescantus.
                            </p>
                            <p className={"Typography-white Typography-alinea Typography-bold"}>
                                Kiesploeg
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.16. Alle leden van de opkomende kiesploeg moeten ingeschreven zijn in een door Diana
                                vzw vertegenwoordigde richting voor minstens 27 studiepunten heel het bestuursjaar lang.
                                Uitzonderingen mogen aangevraagd worden aan het huidig praesidium op een Algemene
                                Vergadering of aan het kiescomité en moeten goedgekeurd worden door de meerderheid van
                                de stemmen.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.17. Het bestuursorgaan (voorzitter, ondervoorzitter, penningmeester & secretaris) moet
                                ingeschreven zijn in een door Diana vzw vertegenwoordigde richting voor minstens 27
                                studiepunten heel het bestuursjaar lang en moet tenminste één aaneensluitend
                                praesidiumjaar ervaring hebben opgedaan als praesidiumlid van Diana. Uitzonderingen
                                mogen aangevraagd worden aan het huidig praesidium op een Algemene Vergadering of aan
                                het kiescomité en moeten goedgekeurd worden door de meerderheid van de stemmen.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.18. Alle leden van de opkomende kiesploeg worden geacht deze statuten te kennen en bij
                                het indienen van de kandidatuur gaan ze automatisch akkoord met deze statuten.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.19. De opkomend praeses kent de statuten en brieft deze ook door aan alle leden van
                                het opkomend praesidium.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.20. Het is voor een kiesploeg verboden om affiches van andere ploegen te overplakken,
                                vernietigen, verwijderen of bekladden.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.21. Een kiesploeg wordt geacht de andere ploegen te respecteren en elke ploeg de kans
                                te geven om affiches en propaganda te verspreiden.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.22. Een kiesploeg mag tot twee weken voor de kiesweek leden werven door aan het
                                kiescomité te vermelden dat deze kiesploeg opkomt als praesidium voor de
                                studentenvereniging Diana, tenzij anders bepaald door het kiescomité.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.23. De naam ‘Diana’ en de kleuren, de symbolen en de tekens die in verband gebracht
                                worden met de VZW Diana mogen op geen enkele wijze gebruikt worden door de ploegen, bij
                                activiteiten noch bij het aanvragen van sponsoring. Verwijzen naar activiteiten van
                                studentenvereniging Diana uit het verleden mag zolang men de hier genoemde regels niet
                                schendt. Het is tevens verboden om de naam ‘Diana’ in de kiesploeg naam te verwerken.
                                Daarnaast moet ook alle drukwerk van een ploeg dat publiek zichtbaar is voorzien zijn
                                van een stempel van Campus Gasthuisberg of Campus Proximus.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.24. Exclusieve sponsoring vanuit de facbar van VZW Diana is verboden. Desbetreffende
                                verantwoordelijken dienen in eer en geweten te handelen.
                            </p>
                            <p className={"Typography-white Typography-alinea Typography-bold"}>
                                Lolploegen
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.25. Ploegen die opkomen zonder het oogmerk om verkozen te worden, maar louter met het
                                doel om een gemoedelijke sfeer te creëren tijdens de kiesweek, worden “lolploegen”
                                genoemd.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.26 Een lolploeg moet minimum uit tweederde artemis leden van Diana bestaan en mag
                                maximum tien leden tellen om er één te kunnen starten. Zij moeten zich kenbaar maken bij
                                het kiescomité ten laatste twee weken voor de aanvang van de kiesweek.
                            </p>
                            {/*//todo Why is 4.27 er 2x da maakt het enorm irritant want nu moet alles 1ne opschuiven!!*/}
                            <p className={"Typography-white Typography-alinea"}>
                                4.27 Een lolploeg wordt niet verwacht hetzelfde kiesreglement te volgen als de kiesploeg
                                aangezien zij niet verkozen kunnen worden. Het kiescomité deelt hen tijdig mee aan welk
                                reglement zij zich dienen te houden.
                            </p>
                            <p className={"Typography-white Typography-alinea Typography-bold"}>
                                De stemming
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.27. De stemming wordt opgedeeld in twee stemrondes, één op Campus Gasthuisberg en
                                Campus Proximus van de UCLL en één tijdens de niet-kiescantus. Beide stemrondes worden
                                georganiseerd door het neutraal comité, dewelke uiterst objectief dienen te blijven bij
                                de stemming en bij het motiveren van stemmers.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.28. Heel de kiesweek lang krijgen de door Diana vertegenwoordigde studenten en
                                personeelsleden de kans om een stem uit te brengen. Deze stemmingen zijn anoniem en
                                enkel beschikbaar via Stemt.org door het neutraal comité.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.29. Op donderdagavond vindt de tweede stemronde plaats tijdens de niet-kiescantus.
                                Elke aanwezige persoon die geldig aanwezig is mag hierop een stem uitbrengen.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.30. Lolploegen zijn verplicht om na de stemming forfait te geven. De stemmen voor de
                                lolploegen worden buiten beschouwing gelaten bij het berekenen van de uitslag.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.31. De stemmen worden geteld onder toezicht van maximaal twee vertegenwoordigers van
                                elke opkomende ploeg. De ploeg met de meerderheid van de uitgebrachte stemmen achter
                                haar naam wordt beschouwd als winnaar. Deze uitslag dient zo snel mogelijk aan alle
                                leden te worden bekendgemaakt.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.32. De bevoegdheden worden formeel overgedragen op het einde van de niet-kiescantus.
                                Dit is tevens het begin van het nieuwe werkjaar. Voor het einde van het academiejaar
                                moeten de nodige documenten die een grondige werking van de VZW garanderen overgedragen
                                worden.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.33. Het optellen van de stemresultaten van de door Diana vertegenwoordigde studenten
                                en personeelsleden en de stemming tijdens de niet-kiescantus zal volgens de
                                verdeelsleutel 70/30 gebeuren. De uitslag van de stemming via Stemt.org zal meetellen
                                voor 70 procent van de uiteindelijke uitslag. De uitslag van de stemming tijdens de
                                niet-kiescantus zal meetellen voor 30 procent van de uiteindelijke uitslag.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                4.34. Gedurende de kiesweek kunnen er strafpunten uitgedeeld worden. (zie regel 4.15.)
                                Strafpunten kunnen uitgedeeld worden tot aan de telling van de stemresultaten. Het
                                kiescomité bepaalt de mate waarin deze doorwegen tijdens de telling.
                            </p>
                        </div>
                    </div>
                </section>

                <section className={isMobile ? "InternReglementPage-section3-mobile" : "InternReglementPage-section3"}>
                    <div
                        className={isMobile ? "InternReglementPage-textBox-right-mobile" : "InternReglementPage-textBox-right"}>
                        <h2 className={"Typography-red"}>Artikel 5. Beleid</h2>

                        <p className={"Typography-red"}>
                            5.1. Minstens één persoon moet de belangen van Diana verdedigen op de praeses vergaderingen
                            van VZW OKeR en de Algemene Vergadering van het campus overleg. Ook moet Diana X aantal
                            personen afvaardigen naar de LOKO AV waarbij X gelijk is aan het aantal stemgerechtigden dat
                            Diana dat bestuursjaar heeft.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            5.2. Elk effectief lid van VZW Diana mag sponsoring voor de vereniging aanvragen, zolang dit
                            gebeurt bij instanties waarvan de geest niet indruist tegen de algemene doelstellingen van
                            de vereniging.
                        </p>

                    </div>
                </section>

                <section className={isMobile ? "InternReglementPage-section4-mobile" : "InternReglementPage-section4"}>
                    <div
                        className={isMobile ? "InternReglementPage-textBox-right-mobile" : "InternReglementPage-textBox-right"}>

                        <h2 className={"Typography-white"}>Artikel 6. Motie van wantrouwen</h2>

                        <p className={"Typography-white"}>
                            Een motie van wantrouwen is een formele aanvraag waarin mogelijke problemen of moeilijkheden
                            omtrent een mede praesidiumlid kan aangehaald worden met als doel dit te bespreken op de
                            eerstvolgende AV waarin alle argumenten besproken wordt en er geëindigd wordt met een
                            stemming of het praesidiumlid al dan niet uit het praesidium verwijderd wordt. Elk
                            praesidiumlid van Diana heeft het recht om een motie van wantrouwen in te dienen tegen elk
                            praesidiumlid.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            De procedure loopt als volgt:
                        </p>

                        <ol className={"InternReglementPage-ol Typography-white"}>
                            <li className={"InternReglementPage-li"}>Praat erover met de persoon in kwestie. Haal je
                                problemen aan in een persoonlijk gesprek (face to face) en probeer het uit te praten.
                            </li>
                            <li className={"InternReglementPage-li"}>Wanneer je het gevoel hebt dat er na een paar
                                dagen/weken geen beterschap toont, haal je het probleem aan bij de Praeses/Vice-Praeses
                                zodat deze je advies kan geven of zelf in gesprek kan gaan met de persoon in kwestie.
                            </li>
                            <li className={"InternReglementPage-li"}>Hierna kan er een indien nodig een gezamenlijk
                                gesprek volgen tussen jou, de Praeses en de persoon in kwestie zodat jullie hierover
                                kunnen praten in een serene setting waarin de Praeses/Vice-Praeses als moderator dient.
                            </li>
                            <li className={"InternReglementPage-li"}>Als je na het doorlopen van de eerste 3 stappen nog
                                altijd geen enkele andere oplossing ziet dan de verwijdering van het praesidiumlid, dan
                                kan je een motie indienen.
                            </li>
                            <li className={"InternReglementPage-li"}>De motie van wantrouwen wordt via mail bezorgd 2
                                weken voor de volgende AV plaatsvindt. De motie wordt gestuurd naar het mailadres
                                praeses@diana.be via het emailadres motieman.voor.moties@gmail.com. De motie moet
                                gemotiveerd worden en de reden die tot uitsluiting leiden mogen niet subjectief zijn,
                                maar moeten aantonen dat het de werking van het praesidium verstoort.
                            </li>
                            <li className={"InternReglementPage-li"}>De motie wordt door de Praeses gecommuniceerd op de
                                eerstvolgende AV.
                            </li>
                            <li className={"InternReglementPage-li"}>De persoon die de motie indient, krijgt de kans om
                                toe te lichten (dit is vrijblijvend). De persoon naar wie de motie gericht is, krijgt
                                voldoende tijd om te reageren.
                            </li>
                            <li className={"InternReglementPage-li"}>De persoon in kwestie wordt gevraagd om de AV
                                tijdelijk te verlaten. Hier is er ruimte voor extra verduidelijking/discussie indien
                                nodig.
                            </li>
                            <li className={"InternReglementPage-li"}>Elk bestuurslid kan één stem uitbrengen waarna deze
                                worden opgeteld. Bij een 2/3e meerderheid van deze stemmen wordt de persoon verwijderd
                                uit het praesidium.
                            </li>
                            <li className={"InternReglementPage-li"}>De persoon wordt binnengeroepen door de
                                Praeses/Vice-Praeses en wordt het resultaat meegedeeld.
                            </li>
                        </ol>

                        <p className={"Typography-white Typography-alinea Typography-italic"}>
                            Uitzondering: Als de persoon in kwestie de Praeses/Vice-Praeses is, spreek dan een neutrale
                            partij aan.
                        </p>
                        <p className={"Typography-white Typography-alinea Typography-italic"}>
                            Het hele proces is strikt vertrouwelijk en alle informatie blijft tussen de bestuursleden.
                        </p>
                    </div>
                </section>

            </motion.section>
            <Footer color={"paper"}/>
        </div>
    )
}
export default InternReglementPage;