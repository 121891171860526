import React from "react";
import {useHistory} from "react-router-dom";
import {motion, useCycle} from "framer-motion";
import MenuItemMobileIcon from "./MenuItemMobileIcon";
import {SubMenuToggleMobile} from "./SubMenuToggleMobile";

interface SubNav {
    name: string;
    route: string
}

interface Props {
    name: string;
    route: string | null
    subMenus: Array<SubNav> | null;
}

const variants = {
    open: {
        x: 0,
        opacity: 1,
        transition: {
            y: {stiffness: 1000, velocity: -100},
        },
        transitionEnd:{
            pointerEvents: "auto" as "auto"
        }
    },
    closed: {
        x: -50,
        opacity: 0,
        transition: {
            y: {stiffness: 1000, velocity: -100},
        },
        transitionEnd:{
            pointerEvents: "none" as "none"
        }
    },
};

const MenuItemMobile = ({name, subMenus, route}: Props) => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    let history = useHistory();
    const handleClick = () => {
        if (route) {
            history.push(route)
        } else {
            toggleOpen()
        }
    }
    return (
        <motion.li
            variants={variants}
            className={"MenuItemMobile-item"}
            onClick={handleClick}
        >
            {!subMenus ? (
                <div className={"MenuItemMobile-link"}
                     onClick={subMenus ? (() => {toggleOpen()}) : (() => {})}
                >
                    <div className={"MenuItemMobile-div"}>
                        <MenuItemMobileIcon name={name}/>
                        <span className={"MenuItemMobile-label"}>{name}</span>
                    </div>
                </div>
            ) : (
                <div className={"MenuItemMobile-link"}>
                    <div className={"MenuItemMobile-div"}>
                        <MenuItemMobileIcon name={name}/>
                        <span className={"MenuItemMobile-label"}>{name}</span>
                    </div>
                    <SubMenuToggleMobile toggle={toggleOpen} open={isOpen}/>
                </div>
            )}
            {subMenus &&
            subMenus.map((item, index) => {
                return (
                    <motion.a
                        className={"MenuItemMobile-dropdownLink"}
                        initial="closed"
                        animate={isOpen ? "open" : "closed"}
                        variants={{
                            closed: {
                                height: 0,
                                opacity: 0,
                                display: "none",
                                transition: {display: {delay: 1}}
                            },
                            open: {
                                height: "60px",
                                opacity: 1,
                                display: "flex"
                            }
                        }}
                        transition={{duration: 0.2}}
                        href={item.route}
                        key={index}
                    >
                        <MenuItemMobileIcon name={item.name}/>
                        <span className={"MenuItemMobile-label"}>{item.name}</span>
                    </motion.a>
                );
            })}
        </motion.li>
    );
};

export default MenuItemMobile;
