import {motion} from "framer-motion";
import Menu from "../components/menu/Menu";
import React from "react";
import Footer from "../components/footer/Footer";
import {useMediaQuery} from "@material-ui/core";
import theme from "../theme";

const StatutenPage = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div className={"Page-root"}>
            <motion.section exit={{opacity: 0}}>
                <Menu/>
                <section className={isMobile? "StatutenPage-section1-mobile" : "StatutenPage-section1"}>
                    <div className={isMobile? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                        <h1 className={"Typography-red"}>Statuten</h1>

                        <p className={"Typography-red"}>
                            Diana’s statuten zijn de grondregels voor het bestuur van onze studentenvereniging.
                            Deze worden periodiek aangepast en uitgebreid in statutaire vergaderingen,
                            en vele kringen hebben grote delen eruit overgenomen voor hun eigen statuten.
                            Aangezien Diana’s leden uiteraard het recht hebben om deze statuten in te zien, bieden wij ze hier online aan.
                        </p>


                    </div>

                </section>

                <section className={isMobile? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                    <div className={isMobile? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>

                        <h2 className={"Typography-white"}>Titel I - Maatschappelijke benaming, zetel, doel en duur</h2>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 1 - Naam
                        </p>
                        <p className={"Typography-white"}>
                            1.1. De vzw draagt de naam: "Diana VZW".
                        </p>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 2 - Maatschappelijke zetel
                        </p>
                        <p className={"Typography-white"}>
                            2.1. De maatschappelijke zetel is gevestigd in het Vlaams Gewest, op het volgende adres: Herestraat 49, 3000 Leuven.
                        </p>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 3 - Doel
                        </p>
                        <p className={"Typography-white"}>
                            3.1. De vereniging heeft als doel:
                        </p>
                        <p className={"Typography-white"}>
                            Diana vzw is een door de University College Leuven Limburg erkende departement kring van Campus Gasthuisberg en Campus Proximus.
                        </p>
                        <p className={"Typography-white"}>
                            Diana vzw wil een aanvulling zijn bij het opgelegde studieprogramma van Campus Gasthuisberg of Campus Proximus op sociaal, cultureel, intellectueel, ontspanning, sportief en maatschappelijk vlak. Ze wil tevens de vriendschapsbanden onder haar leden en met de andere studenten of afgestudeerden van Campus Gasthuisberg of Campus Proximus en het vroegere departement Rega versterken en bevorderen.
                        </p>
                        <p className={"Typography-white"}>
                            De vzw zal niet aan partijpolitiek doen. Zij mag echter wel haar standpunten omtrent ontwikkelingen in het onderwijs publiek maken als deze rechtmatig zijn verworven met gedragenheid door hun actieve leden.
                        </p>
                        <p className={"Typography-white"}>
                            Diana vzw mag haar medewerking verlenen aan alle verenigingen, kringen of instellingen die hetzelfde doel als het hare nastreven.
                        </p>
                        <p className={"Typography-white"}>
                            De vzw mag alle activiteiten ondernemen om haar doel te realiseren, met inbegrip van winstgevende activiteiten binnen de grenzen van wat wettelijk is toegelaten en waarvan de opbrengsten volledig zullen worden bestemd voor de verwezenlijking van de niet-winstgevende doelstellingen.
                        </p>

                        <p className={"Typography-white Typography-alinea"}>
                            3.2. Zij streeft haar doel met alle middelen na, en in het bijzonder als volgt:
                        </p>
                        <p className={"Typography-white"}>
                            Door op sociaal, cultureel, intellectueel, ontspanning, sportief en maatschappelijk vlak activiteiten te organiseren.
                        </p>
                        <p className={"Typography-white"}>
                            Door alumni op regelmatige basis op de hoogte te houden van activiteiten en actualiteit aangaande de vzw.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            3.3. Zij mag alle handelingen verrichten die rechtstreeks of onrechtstreeks verband houden met haar doel of die haar ontwikkeling kunnen bevorderen of vergemakkelijken, met inbegrip van de oprichting van, het beheer van of de deelname in diensten of instellingen die rechtstreeks of onrechtstreeks gericht zijn op de verwezenlijking van het doel dat zij zich heeft gesteld.
                        </p>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 4 - Duur
                        </p>
                        <p className={"Typography-white"}>
                            4.1. De vereniging is opgericht voor een onbepaalde duur.
                        </p>

                        {/*Dit is interessante info maar staat niet in de statuten....*/}

                        {/*<p className={"Typography-white Typography-alinea"}>*/}
                        {/*    1.2. De vereniging wordt opgericht door volgende personen:*/}

                        {/*    <ul className={"StatutenPage-ul"}>*/}
                        {/*        <li className={"StatutenPage-li"}>Lars Leenders</li>*/}
                        {/*        <li className={"StatutenPage-li"}>Dante Schroyens</li>*/}
                        {/*        <li className={"StatutenPage-li"}>Evi Vandenborre</li>*/}
                        {/*        <li className={"StatutenPage-li"}>Gunther Piron</li>*/}
                        {/*        <li className={"StatutenPage-li"}>Linn Vande Populiere</li>*/}
                        {/*    </ul>*/}
                        {/*</p>*/}

                    </div>
                </section>



                <section className={isMobile? "StatutenPage-section3-mobile" : "StatutenPage-section3"}>
                    <div className={isMobile? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>

                        <h2 className={"Typography-red"}>Titel II - Leden</h2>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 5 - Samenstelling
                        </p>
                        <p className={"Typography-red"}>
                            5.1 De vereniging is samengesteld uit vaste leden, toegetreden leden, steunende leden en Honoris Causa
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            5.2. Het aantal vaste leden mag niet minder zijn dan 11. Hun aantal is onbeperkt.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            5.3. Naast de wettelijke vereisten genieten de vaste leden, toegetreden leden, steunende leden en Honoris Causa de rechten en zijn zij gebonden aan de verplichten die in deze statuten staan gespecificeerd.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 6 - Vaste leden
                        </p>
                        <p className={"Typography-red"}>
                            6.1. Zijn vaste leden:
                        </p>
                        <p className={"Typography-red"}>
                            Natuurlijke personen die student zijn of student zijn geweest van Campus Gasthuisberg of Campus Proximus. Zij zijn bevoegd Diana vzw te vertegenwoordigen bij het uitvoeren van activiteiten en met betrekking tot de dagelijkse werking ervan.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            6.2. De vaste leden hebben de volgende rechten en plichten:
                        </p>
                        <p className={"Typography-red"}>
                            Zijn verplicht elk jaar in de periode tussen 1 april en 15 mei een verkiezing te organiseren, conform artikel verkiezing van het <a href={"/intern-reglement"}>intern reglement</a> waarop ze verkozen moeten worden.
                        </p>
                        <p className={"Typography-red"}>
                            Maken deel uit van de Algemene Vergadering.
                        </p>
                        <p className={"Typography-red"}>
                            Hun lidmaatschap is geldig voor één werkingsjaar.
                        </p>


                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 7 - Toetredende leden
                        </p>

                        <p className={"Typography-red"}>
                            7.1. Zijn toetredende leden:
                        </p>
                        <p className={"Typography-red"}>
                            Personen die ingeschreven zijn aan Campus Gasthuisberg of Campus Proximus en jaarlijks het lidgeld betalen. Zij gaan akkoord met de statuten van de vzw en ontvangen een lidkaart van Diana vzw, waardoor zij ook automatisch aangesloten zijn bij de ongevallenverzekering .
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            7.2. De toetredende leden hebben de volgende rechten en plichten:
                        </p>
                        <p className={"Typography-red"}>
                            Mogen deelnemen aan alle openbare activiteiten die Diana vzw organiseert.
                        </p>
                        <p className={"Typography-red"}>
                            Het lidmaatschap eindigt met het begin van het volgend academiejaar van de UCLL en kan verlengd worden bij een nieuwe jaarlijkse bijdrage.
                        </p>


                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 8 - Steunende leden
                        </p>

                        <p className={"Typography-red"}>
                            8.2. Zijn steunende leden:
                        </p>
                        <p className={"Typography-red"}>
                            Alle natuurlijke personen die geen student zijn aan Campus Gasthuisberg en Campus Proximus en jaarlijks het lidgeld betalen. Zij gaan akkoord met de statuten van de vzw en ontvangen een lidkaart van Diana vzw, waardoor zij automatisch aangesloten zijn bij de ongevallenverzekering.
                        </p>

                        <p className={"Typography-red Typography-alinea"}>
                            8.3. Rechten en plichten:
                        </p>
                        <p className={"Typography-red"}>
                            Mogen deelnemen aan alle openbare activiteiten die Diana vzw organiseert.
                        </p>
                        <p className={"Typography-red"}>
                            Het lidmaatschap eindigt met het begin van het volgend academiejaar van de UCLL en kan verlengd worden bij een nieuwe jaarlijkse bijdrage.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 9 - Leden Honoris Causa
                        </p>

                        <p className={"Typography-red"}>
                            9.1. Leden Honoris Causa zijn leden die uitzonderlijke inspanningen hebben geleverd ten behoeve van Diana VZW. Zij zullen tot het einde der dagen kunnen genieten van de voordelen die voor ereleden voorbehouden zijn.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            9.2. De voorbehouden voordelen voor ereleden zijn sterk gelijkend op die van de pro-senioren. Alleen behouden zij het recht om eeuwig lid te zijn. Ongeacht of een Honoris Causa nog student is aan de Associatie KULeuven, zal hij/zij altijd gratis toegang krijgen tot alle cantussen, TD’s en het galabal (diner niet inbegrepen) van Diana VZW.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            9.2.1. Op elke Algemene Vergadering waarbij de effectieve leden met minstens drie vierde aanwezig zijn, is het mogelijk om een persoon voor te dragen, om op te nemen in de lijst van Honoris Causa mits twee derde goedkeuring. Om misbruik van deze voordracht tegen te gaan, moet elke voordracht goedgekeurd worden door de huidig zittende senior vooraleer deze naar de AV mag worden doorverwezen. Indien de huidig zittende senior wordt voorgedragen, ligt de verantwoordelijkheid voor het goedkeuren van deze voordracht bij de huidig zittende vice-senior.
                        </p>
                        <p className={"Typography-red"}>
                            De voordrager dient de huidig zittende senior hiervan op de hoogte te brengen, minstens  drie weken voor de Algemene Vergadering plaatsvindt via mail (praeses@diana.be). Op dat moment wordt een alumni mail uitgestuurd met de melding dat er voor deze persoon een voordracht werd aangevraagd. In deze alumni mail worden de geïnteresseerden uitgenodigd om een woordje van steun/tegenkanting te bieden. Deze geïnteresseerden moeten hiervoor de senior contacteren om uitzonderlijke toegang tot de AV aan te vragen. Op de AV zal de voordracht worden gemotiveerd. Deze voordracht dient ook telkens sterk onderbouwd te zijn, gezien de nodige vervolging van de stemming en de daarbij horende regelingen.
                        </p>

                        <p className={"Typography-red Typography-alinea"}>
                            9.2.2. Als deze stemming een positief antwoord geeft, zal een adviescomité worden opgesteld, opgebouwd uit mensen waarop de genomineerde persoon impact heeft gehad in zijn periode bij Diana. Deze impact kan in eender welke vorm, bijvoorbeeld als praesidium, medewerker, raadgevend, werkgroep, ...
                        </p>
                        <p className={"Typography-red"}>
                            Hiervoor is niet altijd alle informatie bewaard, dus is het aangeraden om alle kanalen te onderzoeken om de volledige impact van de genomineerde in kaart te kunnen brengen.
                        </p>
                        <p className={"Typography-red"}>
                            Het adviescomité wordt opgesteld door de huidig zittende senior, behalve wanneer deze de voordracht heeft aangevraagd. In dat geval wordt de huidige vice-senior hiervoor verantwoordelijk. De voorzitter van dit adviescomité (volgens de hiervoor beschreven regels) stuurt hiervoor een mail uit naar de mensen die met de genomineerde persoon te maken hebben gehad binnen Diana om hen uit te nodigen tot het adviescomité.
                        </p>
                        <p className={"Typography-red"}>
                            Bij het organiseren van het adviescomité dient gekeken te worden naar de situatie. Een te grote groep adviseurs fysiek laten samenkomen is logistiek niet evident. Het adviescomité mag in moeilijke situaties ook online samenkomen of via mail hun advies doorsturen. Het resultaat van dit adviescomité zal worden gebundeld per bestuursjaar en wordt niet geanonimiseerd. Dit gebundelde resultaat zal worden meegenomen naar een tweede stemming.
                        </p>

                        <p className={"Typography-red Typography-alinea"}>
                            9.2.3. De pro-senioren van de bestuursjaren waarbij deze persoon impact, wederom in eender welke vorm, heeft gehad op studentenvereniging Diana zullen gecontacteerd worden. Onderbouwd door het advies uit het adviescomité zal elke pro-senior een anonieme stem uitbrengen op de volgende AV, alsook de huidige senior (tenzij de huidige senior in aanmerking komt dan zal de vice-senior dit doen). Als een pro-senior niet aanwezig kan zijn op de AV kan die zijn stem doorgeven aan de huidige senior met schriftelijke bevestiging (tenzij de huidige senior in aanmerking komt dan zal de vice-senior dit doen). In deze stemming is geen onthouding mogelijk, om een staking der stemmen tegen te gaan.
                        </p>
                        <p className={"Typography-red"}>
                            Nadat elke pro-senior en de huidige senior (tenzij de huidige senior in aanmerking komt dan zal de vice senior dit doen) gestemd hebben, worden de collectieve stemmen opgeteld. Bij een twee derde	meerderheid van deze collectieve stemmen en met een minimum van drie uitgebrachte stemmen, 	wordt de persoon opgenomen in de lijst als Honoris Causa.
                        </p>

                        <p className={"Typography-red Typography-alinea"}>
                            9.3 Op het moment van schrijven, zijnde 19 mei 2021, is dit de lijst van Honoris Causa:
                        </p>
                        <ul className={"StatutenPage-ul Typography-red"}>
                            <li className={"StatutenPage-li"}>Rueben Van Mol</li>
                            <li className={"StatutenPage-li"}>Ylja Stein</li>
                            <li className={"StatutenPage-li"}>Philippe Beeckmans</li>
                            <li className={"StatutenPage-li"}>Gunther Piron</li>
                        </ul>

                        <p className={"Typography-red Typography-alinea"}>
                            9.4. Pro-senioren die niet meer studeren dienen het normale ledentarief op cantussen te betalen, met uitzondering van doopcantus, ontgroeningscantus, zwanenzangcantus, alumnicantus, EJW-cantus daar krijgen ze gratis inkom. Hun toegang op TD’s en het galabal (zonder eten) van Diana VZW blijft gratis.
                        </p>


                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 10 - Ledenregister
                        </p>
                        <p className={"Typography-red"}>
                            10.1. De vereniging houdt, via haar bestuursorgaan, een ledenregister bij in overeenstemming met de wet.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 11 - Ontslag, uitsluiting, schorsing
                        </p>
                        <p className={"Typography-red"}>
                            11.1. Elk  vast lid kan zich te allen tijde uit de vereniging terugtrekken door een schriftelijk ontslag te sturen naar het bestuursorgaan.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            11.2. Het lidmaatschap wordt automatisch beëindigd door overlijden of, in het geval van een rechtspersoon, door ontbinding, fusie, splitsing, nietigheid of faillissement.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            11.3. Het niet naleven van de statuten, ernstige inbreuken op het <a href={"/intern-reglement"}>intern reglement</a>, de gedragsregels, ernstig wangedrag, handelingen of uitingen die de goede naam of het aanzien van de vereniging kunnen aantasten, overlijden of faillissement, zijn handelingen die kunnen leiden tot de uitsluiting van een lid.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            11.4. De uitsluiting van een lid kan slechts worden beslist door de Algemene Vergadering met een meerderheid van tweederde van de aanwezige of vertegenwoordigde stemmen. Het bestuursorgaan kan de betrokken leden schorsen tot het besluit van de Algemene Vergadering.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            11.5. Het onvrijwillig ontslag van een vast lid kan beslist worden door de Algemene Vergadering volgens de procedure omschreven in artikel ‘Motie van Wantrouwen’ van het <a href={"/intern-reglement"}>intern reglement</a>.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            11.6. Een uitgesloten, geschorste of ontslagnemend lid, alsook de erfgenamen van een overleden lid, kunnen geen aanspraak maken op enig gedeelte van het maatschappelijk bezit van de vereniging.
                        </p>

                    </div>

                </section>

                {/*Ik ben hier geraakt*/}

                <section className={isMobile? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                    <div className={isMobile? "StatutenPage-section4-box-mobile" : "StatutenPage-section4-box"}>
                        <div className={isMobile? "StatutenPage-textBox-right-mobile": "StatutenPage-textBox-right"}>
                             <h2 className={"Typography-white"}>Titel III - Bijdragen</h2>

                            <p className={"Typography-white Typography-bold Typography-alinea"}>
                                Artikel 12 – Bijdragen
                            </p>
                            <p className={"Typography-white"}>
                                12.1. De actieve leden zijn niet verplicht om een jaarlijkse bijdrage te betalen, maar vallen wel onder de ongevallenverzekering van Diana VZW op basis dat zij een geldig lidkaart kunnen vertonen. Zij ondersteunen de vereniging actief met hun capaciteiten en toewijding.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                12.2. De gevraagde jaarlijkse bijdrage voor steunende en toetredende leden is onderhevig aan verandering maar mag nooit meer dan €20 bedragen.
                            </p>

                        </div>
                    </div>
                </section>

                <section className={isMobile? "StatutenPage-section3-mobile": "StatutenPage-section3"}>
                    <div className={isMobile? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                        <h2 className={"Typography-red"}>Titel IV – Algemene vergadering</h2>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 13 – Samenstelling
                        </p>
                        <p className={"Typography-red"}>
                            13.1. De Algemene Vergadering brengt alle vaste leden samen.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            13.2. De Algemene Vergadering wordt voorgezeten door de Voorzitter van het bestuursorgaan of, bij gebrek daaraan, door de Ondervoorzitter of  door een ander lid van het bestuursorgaan.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            13.3. Het bestuursorgaan kan elke persoon uitnodigen om de Algemene Vergadering geheel of gedeeltelijk als waarnemer of adviseur bij te wonen. De Algemene Vergadering beslist over de 	opportuniteit van deze uitnodiging.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 14 – Bevoegdheid
                        </p>
                        <p className={"Typography-red"}>
                            14.1. De Algemene Vergadering heeft de bevoegdheden die haar uitdrukkelijk door de wet of de statuten zijn toegekend.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            14.2. Zij is bevoegd voor:
                        </p>
                        <ul className={"StatutenPage-ul Typography-red"}>
                            <li className={"StatutenPage-li"}>de wijziging van de statuten</li>
                            <li className={"StatutenPage-li"}>de benoeming en het ontslag van bestuurders</li>
                            <li className={"StatutenPage-li"}>de benoeming en het ontslag van commissarissen en de vaststelling van hun bezoldiging in geval van toekenning van een bezoldiging</li>
                            <li className={"StatutenPage-li"}>de jaarlijkse kwijting aan bestuurders en eventuele commissarissen</li>
                            <li className={"StatutenPage-li"}>de jaarlijkse goedkeuring van begrotingen en rekeningen</li>
                            <li className={"StatutenPage-li"}>de vrijwillige ontbinding van de vereniging en de benoeming of het ontslag van de vereffenaar</li>
                            <li className={"StatutenPage-li"}>de uitsluiting van de leden</li>
                            <li className={"StatutenPage-li"}>de beslissing om een vordering tot schadevergoeding in te stellen tegen elk lid van de vereniging, elke bestuurder, elke commissaris, elke persoon die gemachtigd is om de vereniging of elke door de algemene vergadering aangewezen vertegenwoordiger te vertegenwoordigen</li>
                            <li className={"StatutenPage-li"}>de omvorming van de vereniging tot een vennootschap met een maatschappelijk doel</li>
                            <li className={"StatutenPage-li"}>de goedkeuring en wijziging van het <a href={"/intern-reglement"}>intern reglement</a></li>
                            <li className={"StatutenPage-li"}>alle andere gevallen die de statuten of de wet vereisen</li>
                        </ul>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 15 – Gewone Algemene Vergadering
                        </p>
                        <p className={"Typography-red"}>
                            15.1. De Gewone Algemene Vergadering wordt ten minste eenmaal per jaar gehouden, binnen zes maanden na de afsluitingsdatum van het boekjaar.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            15.2. Zij heeft verplicht als agendapunt:
                        </p>
                        <ul className={"StatutenPage-ul Typography-red"}>
                            <li className={"StatutenPage-li"}>de voorstelling van het jaarverslag van het bestuursorgaan</li>
                            <li className={"StatutenPage-li"}>de goedkeuring van de rekeningen van het afgelopen boekjaar</li>
                            <li className={"StatutenPage-li"}>de voorlopige begroting voor het volgende boekjaar</li>
                        </ul>


                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 16 – Buitengewone Algemene Vergadering
                        </p>
                        <p className={"Typography-red"}>
                            16.1. De vereniging kan ook te allen tijde bij besluit van het bestuursorgaan in een Buitengewone Algemene Vergadering bijeengeroepen worden, in het bijzonder op verzoek van ten minste één vijfde van de gewone leden.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 17 – Oproeping
                        </p>
                        <p className={"Typography-red"}>
                            17.1. Alle vaste leden moeten ten minste vijftien dagen voor de datum van de algemene vergadering door het bestuursorgaan aan de algemene vergadering worden opgeroepen.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            17.2. In de oproeping worden de dag, het tijdstip, de plaats en de agenda van de vergadering vermeld.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            17.3. Elk voorstel dat door ten minste een twintigste van de vaste leden wordt ondertekend, moet op de 	agenda worden geplaatst.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 18 – Aanwezigheidsquorum
                        </p>
                        <p className={"Typography-red"}>
                            18.1. Behoudens in de gevallen waarin deze statuten of de wet anders bepalen, zal de algemene vergadering geldig beraadslagen zodra de helft van de leden aanwezig of vertegenwoordigd is.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 19 – Volmachten
                        </p>
                        <p className={"Typography-red"}>
                            19.1. Elk vast lid kan zich laten vertegenwoordigen door een gevolmachtigde, mits de vertegenwoordiger zelf vast lid is van de vereniging.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            19.2. Elke vertegenwoordiger mag maximaal beschikken over één volmacht.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 20 – Beraadslagingen
                        </p>
                        <p className={"Typography-red"}>
                            20.1. De Algemene Vergadering beraadslaagt over alle agendapunten. Bij wijze van uitzondering kan het ook geldig beraadslagen over punten die niet op de agenda staan.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            20.2. Alle vaste leden hebben gelijke stemrechten.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            20.3. De besluiten van de Algemene Vergadering worden bij gewone meerderheid van stemmen van de aanwezige of vertegenwoordigde stemmen aangenomen, met uitzonderingen voorzien in deze statuten of 	in de wet.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            20.4. Bij gelijke stemming ligt de beslissende stem bij het bestuursorgaan.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            20.5. Blanco stemmen, ongeldige stemmen en onthoudingen zijn uitgesloten van de berekening.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 21 – Wijziging van de statuten
                        </p>
                        <p className={"Typography-red"}>
                            21.1. De Algemene Vergadering kan slechts tot wijziging van de statuten besluiten indien de wijzigingen uitdrukkelijk in de oproeping worden vermeld en indien ten minste twee derde van de leden aanwezig of vertegenwoordigd zijn.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            21.2. Amendementen worden slechts aanvaard indien zij ten minste twee derde van de stemmen van de aanwezige of vertegenwoordigde leden krijgen, met uitzondering van amendementen die van invloed zijn op het doel van de vereniging, die ten minste vier vijfde van de stemmen van de aanwezige of vertegenwoordigde leden moet krijgen.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            21.3. Indien twee derde van de leden niet aanwezig of vertegenwoordigd is, kan een tweede vergadering worden bijeengeroepen na een periode van ten minste vijftien dagen. Deze tweede vergadering kan geldig beraadslagen over de wijziging van de statuten, ongeacht het aantal aanwezige of vertegenwoordigde leden, maar steeds met betrekking tot de meerderheid van de voorziene stemmen.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 22 – Register van besluiten
                        </p>
                        <p className={"Typography-red"}>
                            22.1. De besluiten van de Vergadering worden vastgelegd in een register van notulen, medeondertekend door de Voorzitter en een bestuurder.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            22.2. Dit register wordt bewaard op de maatschappelijke zetel waar het door alle leden in ruime zin kan worden geraadpleegd, na een schriftelijk verzoek aan het bestuursorgaan waarmee het lid de datum en het tijdstip van de controle moet overeenkomen. Deze datum wordt vastgesteld binnen een maand na ontvangst van het verzoek.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 23 – Bekendmaking van besluiten
                        </p>
                        <p className={"Typography-red"}>
                            23.1. Overeenkomstig de wet worden elke wijziging van de statuten en elke akte betreffende de benoeming of de beëindiging van het mandaat van bestuurders of commissarissen onverwijld neergelegd bij de Griffie van de ondernemingsrechtbank en gepubliceerd in het Belgisch Staatsblad door de griffier.
                        </p>

                    </div>
                </section>

                <section className={isMobile? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                    <div className={isMobile? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>

                        <h2 className={"Typography-white"}>Titel V – Bestuur</h2>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 24 – Samenstelling
                        </p>
                        <p className={"Typography-white"}>
                            24.1. De vereniging wordt bestuurd door een collegiaal bestuursorgaan van minstens drie personen. Dit orgaan wordt het bestuur of het bestuursorgaan genoemd.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            24.2. De bestuurders worden uitsluitend gekozen uit de vaste leden. Daarnaast moeten bestuurders voldoen aan de volgende voorwaarden om in aanmerking te komen voor verkiezing:
                            Bestuurders moeten een richting studeren of gestudeerd hebben die Diana VZW vertegenwoordigd.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            24.3. Zij worden door de Algemene Vergadering benoemd voor een bepaalde duur, gelijk aan de duur tot de volgende verkiezingsperiode. Na afloop van hun mandaat zijn de aftredende leden van het bestuursorgaan herkiesbaar.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            24.4. De bestuurders oefenen hun mandaat kosteloos uit. Zij gaan uit hoofde van hun functie geen enkele persoonlijke verplichting aan. Zij zijn enkel verantwoording verschuldigd aan de vereniging voor de uitvoering van hun mandaat.
                        </p>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 25 – Functies
                        </p>
                        <p className={"Typography-white"}>
                            25.1. Het bestuursorgaan benoemt onder zijn leden een voorzitter, een ondervoorzitter, een penningmeester en een secretaris.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            25.2. In geval van verhindering van de Voorzitter zal zijn functie worden uitgeoefend door de ondervoorzitter of een persoon die door het bestuursorgaan wordt aangewezen.
                        </p>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 26 – Ontslag, herroeping, vacature
                        </p>
                        <p className={"Typography-white"}>
                            26.1. Elke bestuurder die ontslag wenst te nemen, moet het bestuursorgaan schriftelijk op de hoogte brengen van zijn beslissing. Zijn ontslag wordt onmiddellijk van kracht, tenzij dit ertoe leidt dat het aantal 	bestuurders onder het minimum aantal daalt.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            26.2. De bestuurders kunnen te allen tijde door de Algemene Vergadering worden ontslagen volgens de procedure omschreven in artikel ‘Motie van wantrouwen’ van het intern reglement.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            26.3. In geval van een vacature kan de algemene vergadering een voorlopige bewindvoerder aanwijzen. In dat geval voltooit hij de ambtstermijn van de bestuurder die hij vervangt. Indien er geen benoeming plaatsvindt, zal het bestuursorgaan in de vacature voorzien.
                        </p>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 27 – Vergaderingen
                        </p>
                        <p className={"Typography-white"}>
                            27.1. Het bestuur komt bijeen wanneer de behoeften van de vereniging dit vereisen en wanneer de voorzitter of ten minste twee van zijn leden daarom verzoeken.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            27.2. Oproepen tot vergaderingen worden ten minste drie kalenderdagen voor de datum van de vergadering door de secretaris of, bij gebrek daaraan, door een bestuurder, per gewone brief, per e-mail of zelfs mondeling, verzonden. Zij bevatten de agenda, de datum en de plaats waar de vergadering zal worden gehouden. De documenten die ter bespreking aan het bestuursorgaan worden voorgelegd, zijn bij deze mailing gevoegd. Indien zij bij wijze van uitzondering niet beschikbaar blijken te zijn op het ogenblik van de bijeenroeping van de vergadering, moeten zij voor raadpleging beschikbaar zijn.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            27.3. Een bestuurder kan zich laten vertegenwoordigen door een andere bestuurder door middel van een ondertekende schriftelijke volmacht.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            27.4. Elke bestuurder die een vergadering van het bestuursorgaan bijwoont of vertegenwoordigd is geweest op een vergadering van het bestuursorgaan, wordt geacht naar behoren te zijn opgeroepen. Een bestuurder kan ook afzien van het recht om een klacht in te dienen over het ontbreken of de onregelmatigheid van de kennisgeving, voor of na de vergadering waaraan hij of zij niet heeft deelgenomen.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            27.5. Het bestuursorgaan kan op zijn vergaderingen elke persoon uitnodigen wiens aanwezigheid hij noodzakelijk acht, uitsluitend met raadgevende stem.
                        </p>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 28 – Beraadslaging
                        </p>
                        <p className={"Typography-white"}>
                            28.1. Het bestuursorgaan beraadslaagt geldig indien ten minste twee derde van de leden aanwezig of vertegenwoordigd is.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            28.2. Haar besluiten worden bij gewone meerderheid van stemmen genomen. Bij gelijke stemming ligt de beslissende stem bij het bestuursorgaan.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            28.3. De besluiten van het bestuursorgaan worden vastgelegd in de vorm van notulen, medeondertekend door de voorzitter en de secretaris en ingeschreven in een speciaal register. Dit register wordt bijgehouden op de maatschappelijke zetel.
                        </p>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 29 – Bevoegdheden
                        </p>
                        <p className={"Typography-white"}>
                            29.1. Het bestuursorgaan heeft de ruimste bevoegdheden voor de administratie en het beheer van de vereniging. Enkel de handelingen die door de wet of deze statuten aan de algemene vergadering zijn voorbehouden, zijn van haar bevoegdheid uitgesloten.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            29.2. Het bestuursorgaan heeft volgende financiële bevoegdheden. Om financieel wangedrag tegen te gaan, zijn voor elk van deze acties minstens 2 personen met de nodige bevoegdheden nodig:
                        </p>
                            <ul className={"StatutenPage-ul Typography-white"}>
                                <li className={"StatutenPage-li"}>Het afsluiten van een rekening in samenwerking met minstens één van de huidige kaarthouders van deze rekening.</li>
                                <li className={"StatutenPage-li"}>Geld storten of afhalen van de rekening.</li>
                                <li className={"StatutenPage-li"}>Het overzetten van geld van de ene rekening naar de andere in samenwerking met minstens één van de huidige kaarthouders van elke rekening.</li>
                            </ul>

                        <p className={"Typography-white Typography-alinea"}>
                            29.3. Het uittredend bestuursorgaan blijft verantwoordelijk tot de overdracht naar het aantredend bestuursorgaan volledig en correct is afgelopen. De overdracht dient volledig afgerond te zijn, ten laatste 2 maanden na verkiezing van het aantredend bestuursorgaan. De overdracht houdt volgende acties, in volgorde, in:
                        </p>
                            <ul className={"StatutenPage-ul Typography-white"}>
                                <li className={"StatutenPage-li"}>Het goedkeuren van de jaarrekening en boekhouding op de Algemene Vergadering, ten laatste 6 maanden na afsluiten van het boekjaar.</li>
                                <li className={"StatutenPage-li"}>Het inleveren van de jaarrekening bij de griffie van de Ondernemingsrechtbank, ten laatste 30 dagen na goedkeuring op de Algemene Vergadering.</li>
                                <li className={"StatutenPage-li"}>Kwijting van het uittredend bestuursorgaan en benoemen van het aantredend bestuursorgaan op de Algemene Vergadering.</li>
                                <li className={"StatutenPage-li"}>De kwijting en/of benoeming van het bestuursorgaan neerleggen bij de griffie van de Ondernemingsrechtbank, samen met het verslag van de Algemene Vergadering waarop deze kwijting en/of benoeming werd goedgekeurd, ten laatste 30 dagen na goedkeuring op de Algemene Vergadering.</li>
                                <li className={"StatutenPage-li"}>Het overzetten van de rekening(en) en/of kaarthouder(s) naar het aantredend bestuursorgaan.</li>
                            </ul>
                    </div>
                </section>

                <section className={isMobile? "StatutenPage-section3-mobile": "StatutenPage-section3"}>
                    <div className={isMobile? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                        <h2 className={"Typography-red"}>Titel VI – Dagelijks bestuur</h2>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 30 – Dagelijks bestuur
                        </p>
                        <p className={"Typography-red"}>
                            30.1. Het bestuursorgaan kan bepaalde bevoegdheden delegeren aan een dagelijks bestuursorgaan bestaande uit één of meer personen, al dan niet bestuurders, die in die hoedanigheid handelen.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            30.2. De bevoegdheden van het dagelijks bestuur zijn beperkt tot de dagelijkse bestuurshandelingen van 	de vereniging:
                        </p>
                        <ul className={"StatutenPage-ul Typography-red"}>
                            <li className={"StatutenPage-li"}>die de behoeften van het dagelijks beheer van de VZW niet overstijgen</li>
                            <li className={"StatutenPage-li"}>die, wegens hun gebrek aan belang of wegens hun spoedeisend karakter, de tussenkomst van het 	bestuursorgaan niet rechtvaardigen</li>
                        </ul>
                        <p className={"Typography-red Typography-alinea"}>
                            30.3. De duur van het mandaat van de gedelegeerden voor het dagelijks bestuur, dat hernieuwbaar kan zijn, wordt vastgesteld door het bestuursorgaan.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            30.4. Wanneer de gedelegeerd bestuurder ook optreedt als bestuurder, betekent het einde van het mandaat van de bestuurder automatisch het einde van het mandaat van de gedelegeerd bestuurder.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            30.5. Het bestuur kan, op elk ogenblik en zonder zich te moeten verantwoorden, de functie van de verantwoordelijke voor het dagelijks bestuur beëindigen.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            30.6. De handelingen met betrekking tot de benoeming of de beëindiging van de functies van de aan het dagelijks bestuur gedelegeerde personen worden onverwijld ter griffie van de ondernemingsrechtbank neergelegd en overeenkomstig de wet gepubliceerd.
                        </p>
                    </div>
                </section>

                <section className={isMobile? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                    <div className={isMobile? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>

                        <h2 className={"Typography-white"}>Titel VII - Vertegenwoordiging</h2>

                        <p className={"Typography-white Typography-bold Typography-alinea"}>
                            Artikel 31 – Vertegenwoordiging
                        </p>
                        <p className={"Typography-white"}>
                            31.1. Het bestuursorgaan vertegenwoordigt de vereniging in alle gerechtelijke en buitengerechtelijke handelingen.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            31.2. Zij kan deze vertegenwoordiging echter toevertrouwen aan een vertegenwoordigend orgaan bestaande uit één of meer bestuurders of één of meer derden van de vereniging die individueel of gezamenlijk optreden, al naar gelang het geval. De persoon of personen die deel uitmaken van het vertegenwoordigingsorgaan zijn niet verplicht hun bevoegdheden tegenover derden te rechtvaardigen.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            31.3. De duur van hun mandaat en hun eventuele herverkiezing worden bepaald door het bestuur. Zij kunnen te allen tijde door hem worden herroepen.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            31.4. De handelingen met betrekking tot de benoeming of de beëindiging van de functies van de personen 	die bevoegd zijn om de vereniging te vertegenwoordigen, worden onverwijld ter griffie van de ondernemingsrechtbank neergelegd en overeenkomstig de wet gepubliceerd.
                        </p>

                    </div>
                </section>


                <section className={isMobile? "StatutenPage-section3-mobile": "StatutenPage-section3"}>
                    <div className={isMobile? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                        <h2 className={"Typography-red"}>Titel VIII - Overige bepalingen</h2>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 32 – Intern reglement
                        </p>
                        <p className={"Typography-red"}>
                            32.1. Het <a href={"/intern-reglement"}>intern reglement</a> kan door het bestuursorgaan worden voorgelegd aan de Algemene Vergadering. Wijzigingen in dit reglement kunnen worden aangebracht door de Algemene Vergadering, die met tweederde meerderheid van stemmen van de aanwezige of vertegenwoordigde leden besluit.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 33 – Boekjaar
                        </p>
                        <p className={"Typography-red"}>
                            33.1. Het boekjaar begint op 1 juli en eindigt op 30 juni.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 34 – Rekeningen en budgetten
                        </p>
                        <p className={"Typography-red"}>
                            34.1. De rekening van het vorige boekjaar en de begroting van het volgende boekjaar worden jaarlijks ter goedkeuring voorgelegd aan de gewone algemene vergadering.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            34.2. De rekeningen en budgetten van de vereniging worden bijgehouden, bewaard en gepubliceerd in overeenstemming met de wet.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 35 – Raadpleging van registers en boekhouddocumenten
                        </p>
                        <p className={"Typography-red"}>
                            35.1. Ieder lid kan op eenvoudig schriftelijk en met redenen omkleed verzoek aan het bestuursorgaan het ledenregister, alle notulen en besluiten van de algemene vergadering, de Raad van Bestuur en alle boekhoudkundige documenten van de vereniging raadplegen. Het lid dient aan te geven tot welke documenten hij of zij toegang wenst te krijgen. Het bestuursorgaan stelt een datum vast voor de raadpleging van de documenten met het lid. Deze datum wordt vastgesteld binnen een maand na ontvangst van het verzoek.
                        </p>

                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 36 – Ontbinding
                        </p>
                        <p className={"Typography-red"}>
                            36.1. In geval van ontbinding van de vereniging zal de algemene vergadering de vereffenaar(s) benoemen, hun bevoegdheden bepalen en de verdeling van de nettoactiva van de vennootschap aangeven. Deze toewijzing moet gebeuren ten gunste van een belangeloos doel dat zo dicht mogelijk bij dat van de vereniging ligt.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            36.2. Elke beslissing met betrekking tot de ontbinding, de voorwaarden van de vereffening, de benoeming en de beëindiging van de taken van de vereffenaar(s), de sluiting van de vereffening, alsook de toewijzing van de nettoactiva, wordt neergelegd bij de griffie van de ondernemingsrechtbank en gepubliceerd overeenkomstig de wet.
                        </p>
                        <p className={"Typography-red Typography-bold Typography-alinea"}>
                            Artikel 37 – Alles wat niet uitdrukkelijk in deze statuten is voorzien, wordt geregeld door het Wetboek van Vennootschappen en Verenigingen.
                        </p>
                    </div>
                </section>

            </motion.section>
            <Footer color={"primary"}/>
        </div>
    )
}
export default StatutenPage;