import {useMediaQuery} from "@material-ui/core";
import HamburgerMenuMobile from "./mobileMenu/HamburgerMenuMobile";
import HamburgerMenu from "./desktopMenu/HamburgerMenu";
import theme from "../../theme";

const Menu = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    // Depending on the screen size we give the mobile or regular menu
    return (
        <>
            {isMobile ? <HamburgerMenuMobile/> : <HamburgerMenu/>}
        </>
    )
}
export default Menu;