import React, {useEffect, useState} from "react";


interface Props {
    dateStart: number[];
    dateEnd: number[];
    banner: string;
    location: string;
    price: string;
    registration: string;
    facebook:string;
    description: string;
    name: string;
}

//This is a single event as shown on the homepage.

const EventItem = ({dateStart, dateEnd, banner, location, price, registration, facebook, description, name }: Props) => {
    const [finalDate, setFinalDate] = useState("");

    useEffect(() => {
        var timeStart = new Date(dateStart[0], dateStart[1] -1, dateStart[2], dateStart[3], dateStart[4]);
        console.log(timeStart);
        var timeEnd = new Date(dateEnd[0], dateEnd[1] -1, dateEnd[2], dateEnd[3], dateEnd[4]);
        if (dateStart[0] === dateEnd[0] && dateStart[1] === dateEnd[1] && dateStart[2] === dateEnd[2]){
            setFinalDate(
                timeStart.toLocaleString("nl-BE", {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit'})
                +    " - "
                +   timeEnd.toLocaleString("nl-BE", {hour: '2-digit', minute: '2-digit'}))
        } else {
            setFinalDate(
                timeStart.toLocaleString("nl-BE", {weekday: 'short', day: 'numeric' })
                +    " - "
                +   timeEnd.toLocaleString("nl-BE", {weekday: 'short', day: 'numeric', month: 'long', year: 'numeric'}))
        }
    },[dateEnd, dateStart])

    return (
        <div className="root">
            <img  alt={"Banner for " + name} src={banner}  className="image"/>
            <div  className="box">
                <p className="title">{name}</p>
                <div className="datetime-div">
                    <p className="datetime Typography-red">{finalDate}</p>
                    <p className="priceLocation Typography-black">{price} - @{location}</p>
                    <p className="description" >{description}</p>
                </div>
            </div>
            <div className="buttonBox">
                {facebook !== ""?  (<a href={facebook} target="_blank" rel={"noreferrer"} className="btn-outline btn-primary">Facebook</a>) : null}
                {registration !== ""?  (<a href={registration}  target="_blank" rel={"noreferrer"} className="btn-outline btn-dark-gray">Inschrijven</a>) : null}

            </div>
        </div>
    );
}

export default EventItem;