import React, {useCallback, useEffect, useState} from "react";
import { motion } from "framer-motion";
import {PraesidiaObject} from "../../objects/PraesidiaObject";
import {GetFuncties} from "../../api/api";
import {FunctieObject} from "../../objects/FunctieObject";
import {useMediaQuery} from "@material-ui/core";
import theme from "../../theme";

interface Props{
    bestuurslid: PraesidiaObject;
    setSelectedBestuurslid: React.Dispatch<React.SetStateAction<PraesidiaObject | null>>

}

//The opened up version of a praesidium member with the details about his or her function.
export const PraesidiumItem = ({bestuurslid, setSelectedBestuurslid}: Props) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [functies, setFuncties] = useState<FunctieObject[]>();
    const [functieString, setFunctieString] = useState("")


    const getData = useCallback(async () => {
        try {
            const response = await GetFuncties()
            setFuncties(response.data);
        } catch (e) {
        }
    }, []);

    useEffect(() => {
            getData();
            var temp = ""
            for (let i = 0; i < bestuurslid.functies.length; i++) {
                if (i === bestuurslid.functies.length -1){
                    temp += bestuurslid.functies[i];
                } else {
                    temp += bestuurslid.functies[i] + " & "
                }
            }
            setFunctieString(temp);
        }
       , [getData, bestuurslid.functies]
    )


    return (
        <>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, transition: { duration: 0.15 } }}
                transition={{ duration: 0.2, delay: 0.15 }}
                style={{ pointerEvents: "auto" }}
                className={"overlay"}
                onClick={()=> setSelectedBestuurslid(null)}
            >
            </motion.div>
            <motion.button initial={{ opacity: 0 }}
                           animate={{ opacity: 1 }}
                           exit={{ opacity: 0, transition: { duration: 0.15 } }}
                           transition={{ duration: 0.2, delay: 0.15 }}
                           className={"PraesidiumItem-button"}
                           onClick={() => setSelectedBestuurslid(null)}>
                <motion.svg width="23" height="23" viewBox="0 0 23 23" strokeWidth={"3"} fill={"#000"} stroke="hsl(0, 0%, 18%)" strokeLinecap="round">
                    <path
                        d={"M 3 16.5 L 17 2.5"}
                    />
                    <path
                        d={"M 3 2.5 L 17 16.346"}
                    />
                </motion.svg>
            </motion.button>
            <div className={isMobile? "card-content-container open open-mobile" : "card-content-container open"}>

                <motion.div className={"card-content"} layoutId={`card-container-${bestuurslid.id}`}>
                    <motion.div className={"card-top"}>


                        <motion.div
                            className={"card-image-container"}
                            layoutId={`card-image-container-${bestuurslid.id}`}
                        >
                            <img className={"card-image"} src={bestuurslid.image !== "" ? bestuurslid.image : "https://images.diana.be/diana_website/Schild_Vector_White.svg" } alt="" />
                        </motion.div>
                        <motion.div
                            className={"title-container"}
                            layoutId={`title-container-${bestuurslid.id}`}
                        >
                            <h4 className={"category Typography-bold Typography-red"}>{functieString}</h4>
                            <h4>{bestuurslid.naam}</h4>
                        </motion.div>
                    </motion.div>
                    <motion.div className={"content-container"} animate>
                        <>                        {bestuurslid.functies.map((description) => {
                            var result = functies?.map((item) =>{
                                if (item.functie === description){
                                    return(
                                        <>
                                            <h4 className={"Typography-bold Typography-red Typography-alinea"}>{item.functie}</h4>
                                            <p key={description} className={"Typography-red"}>
                                                {item.description}
                                            </p>
                                        </>

                                            )
                                } else {
                                    return null
                                }
                            })
                            return result;
                        })}
                        </>


                    </motion.div>
                </motion.div>
            </div>
        </>
    );
}

export const PraesidiumItemWithoutImage = ({bestuurslid}: Props) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [functies, setFuncties] = useState<FunctieObject[]>();
    const [functieString, setFunctieString] = useState("")


    const getData = useCallback(async () => {
        try {
            const response = await GetFuncties()
            setFuncties(response.data);
        } catch (e) {
        }
    }, []);

    useEffect(() => {
            getData();
            var temp = ""
            for (let i = 0; i < bestuurslid.functies.length; i++) {
                if (i === bestuurslid.functies.length -1){
                    temp += bestuurslid.functies[i];
                } else {
                    temp += bestuurslid.functies[i] + " & "
                }
            }
            setFunctieString(temp);
        }
        , [getData, bestuurslid.functies]
    )
    return (
        <>

            <div className={isMobile? "card-content-container open open-mobile" : "card-content-container open"}>
                            <h4 className={"category Typography-bold Typography-red"}>{functieString}</h4>
                            <h4>{bestuurslid.naam}</h4>
            </div>
        </>
    );
}
