import {motion} from "framer-motion";

//This class is the loading screen with the Diana logo upon first load of the website.
//It is not entirely neccesary but allows the images to load and the api to make its call.
//This can be done fast enough to not need a loading, but a lot of events in the api call and a lot of user traffic could slow it down.
//So in short: This is a failsafe feature.

window.onbeforeunload = function () {
    window.scrollTo(0, 0);
}
const HomeTransition = () => {
    const blackBox = {
        initial: {
            height: "100%",
            bottom: 0,
            paddingBottom: "5%",
            zIndex: 500,
        },
        animate: {
            height: 0,
            paddingBottom: 0,
            transition: {
                when: "afterChildren",
                duration: 1.5,
                ease: [0.87, 0, 0.13, 1],
            },
        },
    };

    const textContainer = {
        initial: {
            opacity: 1,
        },
        animate: {
            opacity: 0,
            transition: {
                duration: 0.3,
                when: "afterChildren",
            },
        },
    };

    const text = {
        initial: {
            y: 40,
            opacity: 0,
        },
        animate: {
            y: 40,
            opacity: 1,
            transition: {
                duration: 1.5,
                ease: [0.87, 0, 0.13, 1],
            },
        },
    };

    return (
        <motion.div
            className={"HomeTransition-root"}
            initial="initial"
            animate="animate"
            variants={blackBox}
            onAnimationStart={() => (document.body.style.overflow = "hidden")}
            onAnimationComplete={() =>
                (document.body.style.overflow = "auto")
            }
        >
            <motion.div variants={textContainer}
                        className={"HomeTransition-schildBox"}>
                <svg className={"HomeTransition-svg"}>
                    <pattern
                        id="pattern"
                        patternUnits="userSpaceOnUse"
                        width={750}
                        height={800}
                        className={"HomeTransition-pattern"}
                    >
                        <rect className={"HomeTransition-rectangle"}/>
                        <motion.rect
                            variants={text}
                            className={"HomeTransition-rect"}
                        />
                    </pattern>
                </svg>
                <motion.img variants={text} src={"https://images.diana.be/diana_website/Schild_Vector_White.svg"} className={"HomeTransition-schild"}/>
            </motion.div>
        </motion.div>
    );
};

export default HomeTransition