import {CssBaseline, ThemeProvider} from "@material-ui/core";
import Routes from "./Routes"
import theme from "./theme";
import {useHistory} from "react-router-dom";
import "./main.scss";
import React, {useState} from "react";
import ReactGA from 'react-ga';

//This class is called in index.tsx and starts up some import things
function App() {
    const [isFirstMount, setIsFirstMount] = useState(true);
    const history = useHistory();

    //This is necessary for Google Analytics tracking
    ReactGA.initialize('G-W42J3XNM2P');
    ReactGA.pageview(window.location.pathname + window.location.search);

    //This is used to make sure the homepage animation only shows upon the first loading of the site and not upon each navigation
    React.useEffect(() => {
        const unlisten = history.listen(() => {
            isFirstMount && setIsFirstMount(false);
        });

        return unlisten;
    }, [history, isFirstMount]);

    //We initialize the CSS configuration here and the router.
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes isFirstMount={isFirstMount}/>
        </ThemeProvider>
    );

}

export default App;
