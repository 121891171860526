import {API_BASE_URL} from "../config";

//This class adds headers to each api request to circumvent CORS and other issues.
//The API_BASE_URL is pulled from the config file.
const customAxios = require('axios').create({
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin" : "*",
        "Access-Control-Allow-Headers" :  "Content-Type",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS"
    },
    baseURL: API_BASE_URL
});

export default customAxios;