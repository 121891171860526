import customAxios from "./customAxios";

//This class is used to get all the data from the backend.
//It uses the API_BASE_URL from the config file to know where to go.
//The customAxios class is used to make the calls itself.
import {API_BASE_URL} from "../config";

export const GetEvents = () => {
    return (customAxios.get(`${API_BASE_URL}/events`));
};

export const GetPraesidia = () => {
    return (customAxios.get(`${API_BASE_URL}/praesidia/current`));
};

export const GetPraesidiaFromYear = (year:string) => {
    return (customAxios.get(`${API_BASE_URL}/praesidia/current/${year}`));
};

export const GetProsenioren = () => {
    return (customAxios.get(`${API_BASE_URL}/prosenioren`));
};

export const GetFuncties = () => {
    return (customAxios.get(`${API_BASE_URL}/functies`));
};

export const GetCurrentYear = () => {
    return (customAxios.get(`${API_BASE_URL}/currentYear`));
};  