import * as React from "react";
import { motion } from "framer-motion";

// @ts-ignore
export const SubMenuToggle = ({ toggle }) => (
    <button className={"SubMenuToggle-button"} onClick={toggle}>
        <motion.svg viewBox="0 0 24 24"
                    fill={"$primary"}
                    className={"SubMenuToggle-svg"}
                    variants={{
                        closed: {
                            rotate: -90
                        },
                        open: {
                            rotate: 0

                        }
                    }}
                    transition={{duration: 0.2}}
        >
            <path
                 d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
            />
        </motion.svg>
    </button>
);
