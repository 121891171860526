import { motion } from "framer-motion"
import {useHistory} from "react-router-dom";

interface Props{
    open: boolean;
    subMenus: Array<SubNav>
}

interface SubNav{
    name: string;
    route: string
}

const liVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        }
    },
    closed: {
        y: -50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        }
    }
};

const SubMenu = ( {open, subMenus}: Props) => {
    let history = useHistory();
    return (
        <>
            {open? (
                <motion.ul className={"SubMenu-ul"}  >
                    {subMenus.map((item) => {
                        return (<motion.li key={item.name} variants={liVariants} className={"SubMenu-li"} onClick={() => {history.push(item.route)}}>{item.name}</motion.li>)
                    })}

                </motion.ul>
            ): null}

        </>
    )
}
export default SubMenu