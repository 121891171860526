import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import EventIcon from '@material-ui/icons/Event';
import SchoolIcon from '@material-ui/icons/School';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GroupIcon from '@material-ui/icons/Group';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import HistoryIcon from '@material-ui/icons/History';
import DescriptionIcon from '@material-ui/icons/Description';
import RoomIcon from '@material-ui/icons/Room';
import GavelIcon from '@material-ui/icons/Gavel';
import StorageIcon from '@material-ui/icons/Storage';
import CommentIcon from '@material-ui/icons/Comment';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import LocalOffer from '@material-ui/icons/LocalOffer';
interface Props{
    name: string;
}

const MenuItemMobileIcon = ({name}: Props) => {
    return (
        <>
            {name === "Home" &&
                <HomeIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"}/>
            }
            {name === "Over Diana" &&
            <InfoIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Studies" &&
            <SchoolIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"}/>
            }
            {name === "Contact" &&
            <QuestionAnswerIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Campus" &&
            <RoomIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Praesidium" &&
            <GroupIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Kringlied" &&
            <MusicNoteIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Geschiedenis" &&
            <HistoryIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Examenwiki" &&
            <DescriptionIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Examenrooster" &&
            <EventIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Reglementen" &&
            <GavelIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "GDPR" &&
            <StorageIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Intern reglement" &&
            <CommentIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Statuten" &&
            <HowToVoteIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Lidkaarten" &&
            <LoyaltyIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Sponsers" &&
            <LocalOffer className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
        </>
    )
}
export default MenuItemMobileIcon