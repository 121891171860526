import React from "react";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

//The music player for the kringliedPage
const MusicPlayer = () => {

return (
    <AudioPlayer
        src="https://images.diana.be/diana_website/Kringlied.mp3"
        showSkipControls={false}
        showJumpControls={false}
        customAdditionalControls={[]}

    />
    )
}

export default MusicPlayer