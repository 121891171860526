import React, {useCallback, useEffect, useState} from "react";
import {GetEvents} from "../../api/api";
import {EventObject} from "../../objects/EventObject";
import EventItem from "./EventItem";
import {useMediaQuery} from "@material-ui/core";
import theme from "../../theme";

//The total overview of events on the homepage
const EventOverview = () => {
    const [events, setEvents] = useState<EventObject[]>();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const getData = useCallback(async () => {
        try {
            const response = await GetEvents()
            setEvents(response.data);
        } catch (e) {
        }
    }, []);

    useEffect(() => {
        getData();
    },[getData])

    return (
        <div className="weekoverview" id="overview" >
            {events?.length === 0 ? (
                <h3
                className={isMobile? "Typography-black Typography-italic Section2-events-title-mobile" : "Typography-black Typography-italic"}>
                Er zijn voorlopig geen evenementen gepland in de nabije toekomst. Kom echter zeker regelmatig eens kijken voor mogelijke updates!
                </h3>
            ) : null}
            <div className="items">
            { events?.map((item) =>
            <EventItem
                key={item.name}
                name={item.name}
                location={item.location}
                description={item.description}
                dateStart={item.startDate}
                dateEnd={item.endDate}
                banner={item.banner}
                facebook={item.facebook}
                price={item.price}
                registration={item.registration} />
            )}
            </div>
        </div>
    )
}

export default EventOverview;