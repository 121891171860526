//This animation makes the item appear from the right side of the screen with a bit of tilt added to the item.
const ImageRightWithRotateAnimation = {
    hidden: {
        x: 50,
        zIndex: 2,
        opacity: 0, },
    visible: {
        x: 0,
        opacity: 1,
        rotate: 8,
        zIndex: 2,
        transition: {
            duration: 1
        }
    }
}

export default ImageRightWithRotateAnimation;