import * as React from "react";
import {motion, useCycle} from "framer-motion";
import {SubMenuToggle} from "./SubMenuToggle";
import SubMenu from "./SubMenu";
import {useHistory} from "react-router-dom";

const variants = {
    open: {
        x: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        },
        transitionEnd:{
            pointerEvents: "auto" as "auto"
        }

    },
    closed: {
        x: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        },
        transitionEnd:{
            pointerEvents: "none" as "none"
        }

    }
};

interface SubNav{
    name: string;
    route: string
}

interface Props{
    name: string;
    subMenus: Array<SubNav> | null;
    route: string | null;
}

export const MenuItem = ({name, subMenus, route}: Props ) => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    let history = useHistory()


    return (
        <motion.li
            variants={variants}
            className={"MenuItem-item"}
            onHoverStart={() => toggleOpen()}
            onHoverEnd={() => toggleOpen()}
            onClick={() => {route && history.push(route)}}
        >
            {subMenus === null ? (
                    <p className={"MenuItem-soloP"}>{name}</p>
                ) : (

                    <motion.div className={"MenuItem-box"}
                                initial={false}
                                animate={isOpen ? "open" : "closed"}
                    >
                        <div className={"MenuItem-subBox"} >
                            <p className={"MenuItem-multiP"}>{name}</p>
                            <SubMenuToggle toggle={() => toggleOpen()}/>
                        </div>
                        <SubMenu subMenus={subMenus} open={isOpen} />
                    </motion.div>
                )

            }
        </motion.li>
    );
};
