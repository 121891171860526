 import {AnimatePresence, AnimateSharedLayout, motion, useAnimation} from 'framer-motion';
 import Menu from '../components/menu/Menu';
 import React, {Props, useCallback, useEffect, useState} from 'react';
 import {GetCurrentYear, GetPraesidia, GetPraesidiaFromYear} from '../api/api';
 import {PraesidiaObject} from '../objects/PraesidiaObject';
 import {PraesidiumCardWithoutPicture} from "../components/praesidiumPage/PraesidiumCard";
 import {PraesidiumItemWithoutImage} from "../components/praesidiumPage/PraesidiumItem";
 import ImageLeftAnimation from "../components/animations/ImageLeftAnimation";
 import {useMediaQuery} from "@material-ui/core";
 import theme from "../theme";
 import {useInView} from "react-intersection-observer";
 import Footer from "../components/footer/Footer";
import { useParams } from 'react-router-dom';

 const PraesidiaPage = ({props}: any) => {
    const year =   props.match.params.year!
    // const {text, match: {params}} = props;
    
    // const {name} = params;

     const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
     const [praesidia, setPraesidia] = useState<PraesidiaObject[]>();
     const [selectedBestuurslid, setSelectedBestuurslid] = useState<PraesidiaObject | null>(null);
     const [currentYear, setCurrentYear] = useState("")
     const getData = useCallback(async () => {
         try {
            if(year == null){
                const response = await GetPraesidia()
                setPraesidia(response.data);
                const response2 = await GetCurrentYear()
                setCurrentYear(response2.data)
            }else{
                const response = await GetPraesidiaFromYear(year)
                setPraesidia(response.data);
                const response2 = await GetCurrentYear()
                setCurrentYear(response2.data)
            }
           
         } catch (e) {
         }
     }, []);

     const [ref, inView] = useInView();
     const controls = useAnimation();

     useEffect(() => {
         if (inView) {
             controls.start('visible');
         }
     })


     useEffect(() => {
         getData();
     }, [getData])

     return (
         <div className={"Page-root"}>
             <motion.section exit={{opacity: 0}}>
                 <Menu/>
                 <div className={"PraesidiaPage-section1"}>
                     <div className={isMobile? "PraesidiaPage-titleBox-mobile": "PraesidiaPage-titleBox"}>
                         <h1 className={"Typography-red"}>Praesidium: {year}</h1>
                         <p className={"Typography-red"}>Het praesidium bestaat uit geëngageerde leden die de verantwoordelijkheid op zich hebben genomen een actieve rol uit te oefenen
                             in het bestuur van onze studentenvereniging.
                         </p>
                         <p className={"Typography-red Typography-alinea"}>
                             Het zijn zij die studentenvereniging Diana maken tot wat het is,
                             en meestal zijn zij dan ook dag en nacht in de weer om al onze activiteiten mogelijk te maken voor onze leden en medestudenten.
                         </p>
                         <p className={"Typography-red"}>
                             Elk van hen staat bovendien open om eventuele vragen te beantwoorden, dus aarzel niet als je met een vraag zit maar vraag het gewoon aan één van onze praesidiumleden!
                         </p>

                         <p className={"Typography-red Typography-alinea"}>Ontmoet het praesidium van academiejaar {year}:</p>
                     </div>
                         <ul className="PraesidiaPage-card-list">
                             {praesidia?.map(bestuurslid => (
                                 <PraesidiumCardWithoutPicture key={bestuurslid.id} currentyear={currentYear} setSelectedBestuurslid={setSelectedBestuurslid} bestuurslid={bestuurslid} />
                             ))}
                         </ul>
                             {selectedBestuurslid && <PraesidiumItemWithoutImage setSelectedBestuurslid={setSelectedBestuurslid} bestuurslid={selectedBestuurslid} key="item" />}
                 </div>
             </motion.section>
             <Footer color={"paper"}/>
         </div>
     )
 }
 export default PraesidiaPage
