import React, {useEffect} from "react";
import {useInView} from "react-intersection-observer";
import TitleAnimation from "../animations/TitleAnimation";
import {motion, useAnimation} from "framer-motion";
import ImageRightAnimation from "../animations/ImageRightAnimation";
import ImageLeftAnimation from "../animations/ImageLeftAnimation";
import ImageRightWithRotateAnimation from "../animations/ImageRightWithRotateAnimation";
import {useMediaQuery} from "@material-ui/core";
import theme from "../../theme";

//Section 3 is the general information about student associations.
const Section3 = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const controls = useAnimation();
    const controls2 = useAnimation();
    const controls3 = useAnimation();
    const controls4 = useAnimation();
    const controls5 = useAnimation();


    const [ref, inView] = useInView();
    const [ref2, inView2] = useInView();
    const [ref3, inView3] = useInView();
    const [ref4, inView4] = useInView();
    const [ref5, inView5] = useInView();


    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (inView2) {
            controls2.start('visible');
        }
        if (inView3) {
            controls3.start('visible');
        }
        if (inView4) {
            controls4.start('visible');
        }
        if (inView5) {
            controls5.start('visible');
        }
    }, [controls, controls2, controls3, controls4, controls5, inView, inView2, inView3, inView4, inView5 ]);

    return(
        <section className={isMobile? "Section3-root-mobile" : "Section3-root"}>
            <div>
                <motion.div
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    variants={TitleAnimation}
                >
                    <h1 className={"Typography-red"}>Wat is Diana?</h1>
                    <p className={"Typography-red Typography-bold"}>
                        Diana is de oudste studentenkring van de UCLL! Maar wat houdt dat eigenlijk in, een studentenvereniging?
                    </p>
                    <p className={"Typography-red Typography-bold"}>
                        We geven je met veel plezier wat uitleg!
                    </p>
                </motion.div>
                <div className={isMobile? "Section3-studentenvereniging-mobile" : "Section3-studentenvereniging"}>


                    <div className={isMobile? "Section3-studentenvereniging-links-mobile": "Section3-studentenvereniging-links"}>
                        <h2 className={"Typography-red"}>Studentenvereniging?</h2>
                        <p className={"Typography-red"}>
                            Diana is een studentenvereniging aan de departementen Gezondheidszorg & Chemie en Management & Technologie van de UCLL Leuven. Vanaf onze oprichting in 1960 engageren we ons voor het welzijn en de belangen van de studenten aan ons hogeschooldepartement.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            De UCLL voorziet immers een mooi aanbod aan studies, maar wij gaan een stap verder: student zijn betekent immers niet alleen blokken, maar ook ontspanning en sport. Diana organiseert fuiven, facavonden, cantussen, sportactiviteiten, filmavonden en nog veel meer activiteiten.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Jullie kunnen ook bij ons terecht om problemen te melden omtrent onze opleidingen, onze campussen en onze werking. Ook als je een leuk idee hebt om het campusleven leuker te maken voor jezelf en je medestudenten horen wij het graag!
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Tegenwoordig vertegenwoordigt Diana zo’n 1700 studenten verspreid over de richtingen die je in de kader ziet.
                        </p>
                        <p className={"Typography-red"}>
                            Diana richt zich dus specifiek tot deze vier professionele bachelors en vijf graduaten, maar iedereen mag en kan lid worden! Zelfs wie studeert op een andere campus, in een andere stad – of zelfs wie géén student meer is: iedereen is welkom bij Diana!
                        </p>
                    </div>
                    <motion.div className={isMobile? "Section3-studentenvereniging-rechts-mobile" :  "Section3-studentenvereniging-rechts"}
                                ref={ref2}
                                animate={controls2}
                                initial="hidden"
                                variants={ImageRightAnimation}
                    >
                        <h2 className={"Typography-black"}>Onze richtingen: </h2>
                        <ul className={"Typography-black Typography-bold Section3-ul"}>
                            <li><p>Biomedische laboratoriumtechnologie</p></li>
                            <li><p>Chemie</p></li>
                            <li><p>Toegepaste informatica</p></li>
                            <li><p>Voedings- en dieetkunde</p></li>
                            <li><p>Graduaat Biotechnologie</p></li>
                            <li><p>Graduaat Elektromechanische systemen</p></li>
                            <li><p>Graduaat Internet of Things</p></li>
                            <li><p>Graduaat Programmeren</p></li>
                            <li><p>Graduaat systeem- en netwerkbeheer</p></li>
                        </ul>
                    </motion.div>
                </div>



                <div className={isMobile? "Section3-doelstellingen-mobile" : "Section3-doelstellingen"}>
                    <div className={isMobile? "Section3-doelstellingen-links-mobile" : "Section3-doelstellingen-links"}>
                        <motion.img
                            ref={ref3}
                            animate={controls3}
                            initial="hidden"
                            variants={ImageLeftAnimation}
                            className={isMobile? "Section3-doelstellingen-img-mobile" : "Section3-doelstellingen-img"}
                            alt={"Diana eerstejaarsweekend 2019"}
                            src={"https://images.diana.be/diana_website/eerstejaarsweekend.png"}/>
                    </div>

                    <div className={isMobile? "Section3-doelstellingen-rechts-mobile" : "Section3-doelstellingen-rechts"}>
                        <h2 className={"Typography-red"}>Onze Doelstellingen</h2>
                        <p className={"Typography-red"}>
                            De student een aangenaam gevoel geven in Leuven is altijd al onze missie geweest.
                            Als eerstejaarsstudent kom je namelijk in een onbekende, verwarrende omgeving terecht waarin een helpende hand of een warm gebaar maar al te vaak ontbreekt.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Daarom trakteert Diana elk jaar opnieuw de nieuwe eerstejaars op een warm onthaal.
                            We bieden naast ons standaardaanbod activiteiten namelijk heel wat activiteiten aan die specifiek op eerstejaars gericht zijn.
                            We denken dan bijvoorbeeld aan het eerstejaarsweekend, en onze peter en meteravond – twee activiteiten die ervoor zorgen dat jullie zich sneller thuis voelen op school,
                            en waarmee je vaak zelfs vrienden voor het leven maakt.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Ook sporters komen bij ons aan bod met heel wat sportactiviteiten, waaronder een voetbalcompetitie en meer.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Diana biedt ook vele ontspanningsmogelijkheden aan. Kijk maar naar onze wekelijkse facavond, onze beruchte TD’s (studentenfuiven) en onze vele cultuuractiviteiten.
                            Bezoek daarom regelmatig onze website en Facebook pagina om op de hoogte te blijven!
                        </p>
                    </div>
                </div>


                <div className={isMobile? "Section3-waarom-mobile" : "Section3-waarom"}>

                    <div className={isMobile? "Section3-waarom-links-mobile" : "Section3-waarom-links"}>
                        <h2 className={"Typography-red"}>Waarom Diana?</h2>
                        <p className={"Typography-red"}>
                            Bij student zijn hoort studeren, maar ook – en zeker niet op de laatste plaats – een studentenvereniging.
                            De kracht van een studentenvereniging zit hem in de onderlinge vriendschappen,
                            de contacten met andere studiegenoten en de mogelijkheid om tips op te doen voor je examens en testen.
                            Je leert er echter ook vaardigheden die je van pas zullen komen in je studies en in het latere beroepsleven.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Als eerstejaars zul je merken dat je in een studentenwereld terecht bent gekomen waar je contacten zich snel beperken
                            tot bijvoorbeeld enkele klasgenoten of een ander select groepje.

                            Zo ervaar je het studentenleven echter maar vanuit één perspectief, terwijl er zoveel meer te beleven en te zien valt!
                            Ons departement is immers slechts een klein onderdeel van de UCLL, en de UCLL op zich is slechts een klein onderdeel van het grootse Leuvense studentenleven.
                            Het zou zonde zijn om je als student te beperken tot slechts een klein stukje van het geheel.
                        </p>
                        {isMobile?
                            <div className={"Section3-waarom-imgBox-mobile"}>
                            <motion.img
                                ref={ref4}
                                animate={controls4}
                                initial="hidden"
                                variants={ImageRightWithRotateAnimation}
                                className={"Section3-waarom-img-mobile"}
                                alt={"Bestuurslint Benjamin"}
                                src={"https://images.diana.be/diana_website/benjamin-lint.png"}/>
                            </div>
                            : null}
                        <p className={"Typography-red Typography-alinea"}>
                            Als lid van Diana leer je op korte tijd zeer veel mensen kennen uit verschillende studierichtingen en studiejaren, en met verschillende achtergronden.
                            Deze contacten staan niet alleen garant voor extra gezelligheid, een veelzijdig gezelschap verbreedt ook je eigen horizon.
                            Bovendien is het altijd handig om in contact te komen met ouderejaars die je extra tips kunnen geven over je studies.
                            Zij hebben reeds de nodige ervaring achter de rug en kunnen je een aardig duwtje in de rug geven!
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Omdat er binnen een studentenvereniging veel jaargangen en studierichtingen samen komen is het eenvoudig om informatie,
                            cursussen en notities te vergaren van vakken uit je jaarprogramma.
                            Je bouwt automatisch ook een netwerk uit van contacten die je eventueel later aan een baan kunnen helpen.
                            Actief zijn binnen een studentenvereniging hoeft bovendien niet ten koste te gaan van je studies.
                            Je bepaalt immers zelf je niveau van participatie en kan dus kiezen hoeveel tijd je in de studentenvereniging wenst te investeren.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Een actief studentenleven en goede studieresultaten zijn dan ook prima te combineren.
                            Zoals vele van onze leden reeds bewezen hebben kan een studentenvereniging zelfs een positieve invloed zijn op je schoolresultaten!
                            Onze leden stimuleren elkaar en studeren in sommige gevallen bijvoorbeeld ook gezamenlijk.
                        </p>
                    </div>

                    <div className={isMobile? "Section3-waarom-rechts-mobile" :"Section3-waarom-rechts"}>
                        {!isMobile?
                        <motion.img
                            ref={ref4}
                            animate={controls4}
                            initial="hidden"
                            variants={ImageRightWithRotateAnimation}
                            className={"Section3-waarom-img"}
                            alt={"Bestuurslint Benjamin"}
                            src={"https://images.diana.be/diana_website/benjamin-lint.png"}/>
                        : null}
                        <motion.img
                            ref={ref5}
                            animate={controls5}
                            initial="hidden"
                            variants={ImageRightAnimation}
                            className={isMobile? "Section3-waarom-img2-mobile" : "Section3-waarom-img2"}
                            alt={"Bestuurslint Benjamin"}
                            src={"https://images.diana.be/diana_website/eerstejaarsweekend2.png"}/>
                    </div>

                </div>

                <p className={"Typography-red Typography-bold"}>
                    Kortom: Diana is een studentenvereniging met een rijke geschiedenis en een groot aanbod aan activiteiten, georganiseerd voor studenten, door studenten!
                </p>

            </div>
        </section>
    )
}
export default Section3;