import * as React from "react";
import {useRef} from "react";
import {motion, useCycle} from "framer-motion";
import {UseDimensions} from "../UseDimensions";
import NavigationMobile from "./NavigationMobile";
import {MenuToggleMobile} from "./MenuToggleMobile";


const sidebar = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 50px 50px)`,
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2
        }
    }),
    closed: {
        clipPath: "circle(30px at 50px 50px)",
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};


const HamburgerMenuMobile = () => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const {height} = UseDimensions(containerRef);

    return (
        <div className={"HamburgerMenu-root"}>
            <motion.nav
                initial={"closed"}
                animate={isOpen ? "open" : "closed"}
                custom={height}
                ref={containerRef}
            >
                <motion.div className={"HamburgerMenu-background-mobile"} variants={sidebar}/>
                <NavigationMobile/>
                <MenuToggleMobile toggle={() => toggleOpen()}/>
            </motion.nav>
        </div>
    );
};
export default HamburgerMenuMobile;