 import {AnimatePresence, AnimateSharedLayout, motion, useAnimation} from 'framer-motion';
 import Menu from '../components/menu/Menu';
 import React, {Props, useCallback, useEffect, useState} from 'react';
 import {GetCurrentYear, GetPraesidia, GetPraesidiaFromYear} from '../api/api';
 import {PraesidiaObject} from '../objects/PraesidiaObject';
 import {PraesidiumCard} from "../components/praesidiumPage/PraesidiumCard";
 import {PraesidiumItem} from "../components/praesidiumPage/PraesidiumItem";
 import ImageLeftAnimation from "../components/animations/ImageLeftAnimation";
 import {useMediaQuery} from "@material-ui/core";
 import theme from "../theme";
 import {useInView} from "react-intersection-observer";
 import Footer from "../components/footer/Footer";
import { useParams } from 'react-router-dom';

 const PraesidiaPage = ({props}: any) => {
     const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
     const [praesidia, setPraesidia] = useState<PraesidiaObject[]>();
     const [selectedBestuurslid, setSelectedBestuurslid] = useState<PraesidiaObject | null>(null);
     const [currentYear, setCurrentYear] = useState("")
     const getData = useCallback(async () => {
         try {
      
                const response = await GetPraesidia()
                setPraesidia(response.data);
                const response2 = await GetCurrentYear()
                setCurrentYear(response2.data)
       
           
         } catch (e) {
         }
     }, []);

     const [ref, inView] = useInView();
     const controls = useAnimation();

     useEffect(() => {
         if (inView) {
             controls.start('visible');
         }
     })


     useEffect(() => {
         getData();
     }, [getData])

     return (
         <div className={"Page-root"}>
             <motion.section exit={{opacity: 0}}>
                 <Menu/>
                 <div className={"PraesidiaPage-section1"}>
                     <div className={isMobile? "PraesidiaPage-titleBox-mobile": "PraesidiaPage-titleBox"}>
                         <h1 className={"Typography-red"}>Praesidium</h1>
                         <p className={"Typography-red"}>Het praesidium bestaat uit geëngageerde leden die de verantwoordelijkheid op zich hebben genomen een actieve rol uit te oefenen
                             in het bestuur van onze studentenvereniging.
                         </p>
                         <p className={"Typography-red Typography-alinea"}>
                             Het zijn zij die studentenvereniging Diana maken tot wat het is,
                             en meestal zijn zij dan ook dag en nacht in de weer om al onze activiteiten mogelijk te maken voor onze leden en medestudenten.
                         </p>
                         <p className={"Typography-red"}>
                             Elk van hen staat bovendien open om eventuele vragen te beantwoorden, dus aarzel niet als je met een vraag zit maar vraag het gewoon aan één van onze praesidiumleden!
                         </p>

                         <p className={"Typography-red Typography-alinea"}>Ontmoet het praesidium van academiejaar {currentYear}:</p>
                     </div>

                     <AnimateSharedLayout type="crossfade">
                         <p className={"Typography-red Typography-bold Typography-italic PraesidiaPage-subTitle"}>Klik op de foto's voor meer informatie omtrent de functies</p>

                         <ul className="PraesidiaPage-card-list">
                             {praesidia?.map(bestuurslid => (
                                 <PraesidiumCard key={bestuurslid.id} currentyear={currentYear} setSelectedBestuurslid={setSelectedBestuurslid} bestuurslid={bestuurslid} />
                             ))}
                         </ul>

                         <AnimatePresence>
                             {selectedBestuurslid && <PraesidiumItem setSelectedBestuurslid={setSelectedBestuurslid} bestuurslid={selectedBestuurslid} key="item" />}
                         </AnimatePresence>
                     </AnimateSharedLayout>
                 </div>


             </motion.section>
             <motion.section className={isMobile? "PraesidiaPage-section2-mobile" : "PraesidiaPage-section2"}>
                 <div className={isMobile? "PraesidiaPage-imageBox-left-mobile" : "PraesidiaPage-imageBox-left"}>
                     <motion.img
                         alt="Diana schachtenlied"
                         src={"https://images.diana.be/diana_website/medewerkers.jpg"}
                         className={isMobile? "PraesidiaPage-image-mobile" : "PraesidiaPage-image"}
                         ref={ref}
                         animate={controls}
                         initial="hidden"
                         variants={ImageLeftAnimation}
                     />
                     <p className={"Typography-black Typography-italic Typography-bold PraesidiaPage-image-subText-left"}>De kookploeg voor het eerstejaarsweekend 2019, bestaande uit medewerkers.</p>
                 </div>
                 <div className={isMobile? "PraesidiaPage-textBox-right-mobile" : "PraesidiaPage-textBox-right"}>
                     <h1 className={"Typography-white"}>Medewerkers</h1>

                     <p className={"Typography-white"}>
                         Diana krijgt buiten de vaste praesidiumleden ook nog bijstand van een trouwe groep medewerkers.
                         Zij steken waar nodig een handje toe bij het organiseren van Diana’s activiteiten,
                         waarvoor we hen dan ook ontzettend dankbaar zijn!
                     </p>
                 </div>
             </motion.section>
             <Footer color={"paper"}/>
         </div>
     )
 }
 export default PraesidiaPage
