import React from "react";
import HomeTransition from "../components/animations/HomeTransition";
import Menu from "../components/menu/Menu";
import { motion } from "framer-motion"
import Footer from "../components/footer/Footer";
import Section3 from "../components/homePage/Section3";
import Section2 from "../components/homePage/Section2";
import Section1 from "../components/homePage/Section1";


export interface Props {
    isFirstMount: boolean;
}

const HomePage = ({isFirstMount}: Props) => {
    return (
            <div className={"Page-root"}>
                <motion.section exit={{opacity: 0}} className={"Page-section"}>
                    {isFirstMount.valueOf() && <HomeTransition/>}
                    <Menu/>
                    <div className={"HomePage-picture"}/>
                    <Section1/>
                    <Section2/>
                    <Section3/>

                </motion.section>
                <Footer color={"primary"}/>
            </div>
    )
}

export default HomePage;