//The animation used for the 'richtingen' on the HomePage.

const TitleAnimation = {
    hidden: {
        y: 50,
        opacity: 0, },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 1
        }
    }
}

export default TitleAnimation;