import { motion } from "framer-motion";
import {PraesidiaObject} from "../../objects/PraesidiaObject";
import {useCallback, useEffect, useState} from "react";
import { GetCurrentYear, GetPraesidia } from "../../api/api";

interface Props{
    bestuurslid: PraesidiaObject;
    setSelectedBestuurslid: React.Dispatch<React.SetStateAction<PraesidiaObject | null>>
    currentyear: String;
    
}

//The closed version of the praesidium member, with his/her function and name
export const PraesidiumCard = ({bestuurslid, setSelectedBestuurslid,currentyear}: Props) => {
   const state = { showing: currentyear==bestuurslid.jaar };
    const [functieString, setFunctieString] = useState("")
    console.log(bestuurslid);
    
    useEffect(() => {
        var temp = ""
        for (let i = 0; i < bestuurslid.functies.length; i++) {
            if (i === bestuurslid.functies.length -1){
                temp += bestuurslid.functies[i];
            } else {
                temp += bestuurslid.functies[i] + " & "
            }
        }
        setFunctieString(temp);
    }, [bestuurslid.functies])

    return (
        <li className={`card`}>
            <div className="card-content-container" onClick={() => setSelectedBestuurslid(bestuurslid)}>
                <motion.div className="card-content" layoutId={`card-container-${bestuurslid.id}`}>
                { state.showing 
                    ? <motion.div
                    className="card-image-container"
                    layoutId={`card-image-container-${bestuurslid.id}`}
                >
                    <img className="card-image" src={bestuurslid.image !== "" ? bestuurslid.image : "https://images.diana.be/diana_website/Schild_Vector_White.svg" } alt="" />
                </motion.div>
                    : null
                }
                    
                    <motion.div
                        className={"title-container2"}
                        layoutId={`title-container-${bestuurslid.id}`}
                    >
                        <h4 className="category Typography-bold Typography-red">{functieString}</h4>
                        <h4>{bestuurslid.naam}</h4>
                    </motion.div>
                </motion.div>
            </div>
        </li>
    );
}

export const PraesidiumCardWithoutPicture = ({bestuurslid, setSelectedBestuurslid,currentyear}: Props) => {
    const [functieString, setFunctieString] = useState("")
    console.log(bestuurslid);

    useEffect(() => {
        var temp = ""
        for (let i = 0; i < bestuurslid.functies.length; i++) {
            if (i === bestuurslid.functies.length -1){
                temp += bestuurslid.functies[i];
            } else {
                temp += bestuurslid.functies[i] + " & "
            }
        }
        setFunctieString(temp);
    }, [bestuurslid.functies])

    return (
        <li className={`card`}>
            <div className="card-content-container" onClick={() => setSelectedBestuurslid(bestuurslid)}>
                        <h4 className="category Typography-bold Typography-red">{functieString}</h4>
                        <h4>{bestuurslid.naam}</h4>
            </div>
        </li>
    );
}


