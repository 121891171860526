import palette from "./palette";
import {createMuiTheme, Theme} from "@material-ui/core";

//Not necesarrily used anymore. interesting for use with Material-UI so leaving it here
const theme: Theme = createMuiTheme(
    {palette,
    },
);

export default theme;
