
//This animation makes the item appear from the right side of the screen

const ImageRightAnimation = {
    hidden: {
        x: 50,
        opacity: 0, },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1
        }
    }
}

export default ImageRightAnimation;