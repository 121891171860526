import {motion} from "framer-motion";
import Menu from "../components/menu/Menu";
import React from "react";
import Footer from "../components/footer/Footer";
import {useMediaQuery} from "@material-ui/core";
import theme from "../theme";

const GDPRPage = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div className={"Page-root"}>
            <motion.section exit={{opacity: 0}}>
                <Menu/>
                <section className={isMobile? "GDPRPage-section1-mobile" : "GDPRPage-section1"}>
                    <div className={isMobile? "GDPRPage-textBox-right-mobile" : "GDPRPage-textBox-right"}>
                        <h1 className={"Typography-red"}>Privacy Statement Diana</h1>

                        <p className={"Typography-red"}>
                            Diana is verantwoordelijk voor de verwerking van persoonsgegevens van haar leden. In dit privacy statement leggen we uit waarom en op welke manier we uw persoonsgegevens verwerken.
                        </p>

                        <p className={"Typography-red Typography-alinea"}>
                            Wij helpen u graag met uw vragen. U kan ons altijd contacteren op admin@diana.be of algemeen@diana.be
                        </p>


                    </div>

                </section>

                <section className={isMobile? "GDPRPage-section4-mobile" : "GDPRPage-section4"}>
                    <div className={isMobile? "GDPRPage-textBox-right-mobile" : "GDPRPage-textBox-right"}>

                        <h2 className={"Typography-white"}>Wie zijn wij?</h2>

                        <p className={"Typography-white"}>
                            Diana is een studentenvereniging aan de departementen Management & Technologie en Gezondheidszorg & Chemie van de UC Leuven Limburg. Sinds 1960 engageren wij ons voor het organiseren van leuke en studentikoze activiteiten voor onze studenten, en hebben we vriendschapsbanden voor het leven geschapen.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            Wij zijn gevestigd aan het Departement Gezondheidszorg en Chemie, Herestraat 49 te 3000 Leuven.
                        </p>

                    </div>
                </section>



                <section className={isMobile? "GDPRPage-section3-mobile" : "GDPRPage-section3"}>
                    <div className={isMobile? "GDPRPage-textBox-right-mobile" : "GDPRPage-textBox-right"}>

                        <h2 className={"Typography-red"}>Welke persoonsgegevens verwerken wij?</h2>

                        <p className={"Typography-red"}>
                            Wij verwerken persoonsgegevens voor volgende doeleinden:
                        </p>
                        <p className={"Typography-red Typography-alinea Typography-bold"}>
                            Nieuwsbrieven en mails
                        </p>
                        <p className={"Typography-red"}>
                            Diana beschikt over een Alumni-nieuwsbrief. Als u geen nieuwsbrieven meer wil ontvangen, dan kan u zich via mail uitschrijven.
                        </p>

                        <p className={"Typography-red Typography-alinea Typography-bold"}>
                            Evenementen
                        </p>
                        <p className={"Typography-red"}>
                            Bij het organiseren van bepaalde evenementen wordt u om uw persoonlijke gegevens gevraagd. Dit heeft als doeleind een overzicht te creëren van de deelnemers en contact op te nemen indien er zich een probleem voordoet.
                        </p>

                        <p className={"Typography-red Typography-alinea Typography-bold"}>
                            Verwerking van foto’s
                        </p>
                        <p className={"Typography-red"}>
                            Toestemming moet niet schriftelijk worden gegeven, maar kan ook mondeling of stilzwijgend worden gegeven, zolang men er zich bewust van is dat de foto online kan worden gepubliceerd. Een mondelinge of stilzwijgende toestemming is moeilijk bewijsbaar. Daarom informeren we duidelijk op voorhand aan (via privacyverklaring, op het deelnemersformulier, via affiche, Facebook, aan de inkom, …) dat er sfeerbeelden worden genomen tijdens onze activiteiten. Voor het gebruik van gerichte foto’s, zeker voor promotionele doelen, vragen we steeds de toestemming van de betrokkene(n).
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Foto’s die worden gemaakt van het aanwezige publiek, waarbij er niet wordt gefocust op één of enkele personen worden gezien als sfeerfoto’s en mogen worden gebruikt zonder toestemming.  Bij gebruik van deze foto’s in een publicatie (zoals bijvoorbeeld een folder of een affiche), worden de herkenbare mensen om toestemming gevraagd.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Personen die niet gefotografeerd willen worden, kunnen zich op voorhand kenbaar maken. Wij zullen deze personen (bijvoorbeeld) een rode sticker geven om deze kenbaar te maken; Zij die niet willen dat de foto’s gepubliceerd worden, krijgen dan een ander kleur.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Foto’s die worden gemaakt zullen x lang bewaard worden.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Waar of wanneer is er geen toestemming nodig?
                        </p>
                        <ul className={"GDPRPage-ul"}>
                            <li className={"GDPRPage-li Typography-red"}>Van een menigte</li>
                            <li className={"GDPRPage-li Typography-red"}>Van publieke personen</li>
                            <li className={"GDPRPage-li Typography-red"}>Bij privé opnames</li>
                            <li className={"GDPRPage-li Typography-red"}>Bij een gebeurtenis met nieuwswaarde</li>
                        </ul>
                        <p className={"Typography-red Typography-alinea"}>
                            Voor bovenstaande doeleinden kunnen wij volgende persoonsgegevens van u vragen, opslaan en verwerken:
                        </p>
                        <ul className={"GDPRPage-ul"}>
                            <li className={"GDPRPage-li Typography-red"}>Naam en voornaam</li>
                            <li className={"GDPRPage-li Typography-red"}>E-mailadres</li>
                            <li className={"GDPRPage-li Typography-red"}>Gsm-nummer</li>
                            <li className={"GDPRPage-li Typography-red"}>Studentennummer</li>
                            <li className={"GDPRPage-li Typography-red"}>Studentenvereniging</li>
                            <li className={"GDPRPage-li Typography-red"}>Gegevens van familieleden</li>
                            <li className={"GDPRPage-li Typography-red"}>Medische gegevens</li>
                        </ul>
                    </div>

                </section>


                <section className={isMobile? "GDPRPage-section4-mobile" : "GDPRPage-section4"}>
                    <div className={isMobile? "GDPRPage-section4-box-mobile" : "GDPRPage-section4-box"}>
                        <div className={isMobile? "GDPRPage-textBox-right-mobile": "GDPRPage-textBox-right"}>
                            <h2 className={"Typography-white"}>Ontvangers van persoonsgegevens</h2>

                            <p className={"Typography-white"}>
                                De gegevens die Diana van u ontvangt worden niet doorgegeven of verkocht aan derden. We zullen alleen gegevens aan derden verstrekken als we daarvoor uw toestemming hebben gekregen. Persoonlijke gegevens van de alumni worden opgeslagen voor het versturen van de Alumni nieuwsbrief.
                            </p>
                        </div>
                    </div>
                </section>

                <section className={isMobile? "GDPRPage-section3-mobile": "GDPRPage-section3"}>
                    <div className={isMobile? "GDPRPage-textBox-right-mobile" : "GDPRPage-textBox-right"}>
                        <h2 className={"Typography-red"}>Bewaartermijnen</h2>

                        <p className={"Typography-red"}>
                            Uw persoonlijke gegevens worden voornamelijk opgeslagen om een overzicht te creëren bij bepaalde evenementen. Daarna worden deze niet meer gebruikt. Wij bewaren deze persoonsgegevens niet langer dan noodzakelijk. De gegevens van de alumni worden voor onbepaalde tijd opgeslagen. U mag ons dan ook ten alle tijden een mail sturen waarin u vermeld dat u geen mails meer wil ontvangen.
                        </p>
                    </div>
                </section>

                <section className={isMobile? "GDPRPage-section4-mobile" : "GDPRPage-section4"}>
                    <div className={isMobile? "GDPRPage-textBox-right-mobile" : "GDPRPage-textBox-right"}>

                        <h2 className={"Typography-white"}>Uw rechten omtrent uw gegevens</h2>

                        <p className={"Typography-white Typography-alinea Typography-bold"}>
                            Recht op inzage
                        </p>
                        <p className={"Typography-white"}>
                            U heeft ten alle tijden recht om na te vragen welke gegevens we van u verwerken.
                        </p>

                        <p className={"Typography-white Typography-alinea Typography-bold"}>
                            Rectificatie
                        </p>
                        <p className={"Typography-white"}>
                            Als u denkt dat wij over de verkeerde gegevens beschikken laat het ons dan gerust weten.
                        </p>

                        <p className={"Typography-white Typography-alinea Typography-bold"}>
                            Wissen van de persoonsgegevens
                        </p>
                        <p className={"Typography-white"}>
                            U kan de persoonsgegevens die wij van u hebben verwijderen.
                        </p>

                        <p className={"Typography-white Typography-alinea Typography-bold"}>
                            Intrekken van uw toestemming
                        </p>
                        <p className={"Typography-white"}>
                            Wanneer de persoonsgegevens worden verwerkt op basis van uw toestemming, dan heeft u het recht de toestemming weer in te trekken. Nieuwe gegevens mogen dan niet meer worden verzameld, de oude gegevens mogen gewoon bewaard blijven voor de aangegeven periode, want deze zijn gewoon rechtmatig verzameld (tenzij er een verzoek tot verwijdering wordt gedaan en het geen wettelijke of contractuele verplichting of een noodzakelijke voorwaarde is).
                        </p>

                        <p className={"Typography-white Typography-alinea Typography-bold"}>
                            Klacht indienen bij de AP
                        </p>
                        <p className={"Typography-white"}>
                            Als u denkt dat wij niet handelen in overeenstemming met de privacywetgeving, dan horen we dat natuurlijk graag. U kan hierover een klacht indienen bij de Autoriteit Persoonsgegevens.                        </p>


                    </div>
                </section>

                <section className={isMobile? "GDPRPage-section3-mobile": "GDPRPage-section3"}>
                    <div className={isMobile? "GDPRPage-textBox-right-mobile" : "GDPRPage-textBox-right"}>
                        <h2 className={"Typography-red"}>Links</h2>

                        <p className={"Typography-red"}>
                            Op de website van Diana, de instagram van Diana, de Facebook-evenementen en alle mogelijke vormen van communicatie vanuit Diana zijn mogelijk een aantal verwijzingen naar andere websites van organisaties te vinden. Diana is niet verantwoordelijk voor de manier waarop die partijen persoonsgegevens verwerken. Daarvoor kun u het privacy statement van de betreffende organisatie lezen.
                        </p>
                    </div>
                </section>

            </motion.section>
            <Footer color={"paper"}/>
        </div>
    )
}
export default GDPRPage;