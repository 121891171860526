import createPalette from "@material-ui/core/styles/createPalette";

//This has been replaced by _colors.scss, but is still handy in combination with material-ui. It has been left in but isn't being used.
const palette = createPalette({
    primary: {
        main: '#910505',
        dark: '#D92528'

    },
    secondary: {
        main: '#FFFFFF',
        light: '#FCC137'
    },
    background: {
        paper: '#ffffff'
    },


});

export default palette;