import { useEffect } from 'react';
import {useHistory} from 'react-router-dom';

//This class is used on every page to make sure the user starts at the top of each page upon navigation.
const ScrollToTop = () => {
    let history = useHistory();
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return null;
}

export default ScrollToTop;