import React from "react";
import {default as data} from "../MenuData.json";
import MenuItemMobile from "./MenuItemMobile";
import {motion} from "framer-motion";
const variants = {
    open: {
        zIndex: 5,
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
        zIndex: -1,
        transition: { staggerChildren: 0.05, staggerDirection: 1, delayChildren: 0.2  },
    }
};


const NavigationMobile = () => {
    return (
        <motion.ul variants={variants} className={"NavigationMobile-list"}>
            {data.map((item) => {
               return(<MenuItemMobile key={item.name} name={item.name} route={item.route} subMenus={item.subMenus}/>)
            })}
        </motion.ul>
    );
};

export default NavigationMobile;
