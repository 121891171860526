import {motion, useAnimation, useSpring, useTransform, useViewportScroll} from "framer-motion";
import EventOverview from "./EventOverview";
import React, {useEffect} from "react";
import {useInView} from "react-intersection-observer";
import TitleAnimation from "../animations/TitleAnimation";
import {useMediaQuery} from "@material-ui/core";
import theme from "../../theme";

//Section 2 of the homepage is the coming events with some explanation
const Section2 = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { scrollY } = useViewportScroll()
    const yRange = useTransform(scrollY, [1, 0], [0, 1]);
    const opacity = useSpring(yRange, { stiffness: 400, damping: 40 });
    const controls = useAnimation();
    const { ref, inView } = useInView();
    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);


    return(
        <section className={isMobile? "Section2-root-mobile" : "Section2-root"}>
            <motion.section id={"section04"}
                            style={{ opacity }}>
                <p><span></span></p>
            </motion.section>
            <div className={"Section2-events"} id={"events"}>
                <motion.h1
                    ref={ref}
                    initial="hidden"
                    animate={controls}
                    variants={TitleAnimation}
                    className={isMobile? "Typography-white Section2-events-title-mobile" : "Typography-white"}>

                        Komende evenementen

                </motion.h1>
                <EventOverview/>

                <p className={isMobile? "Typography-white Section2-eventText-mobile" : "Typography-white Section2-eventText"}>
                    Student zijn betekent niet alleen blokken, maar ook ontspanning en sport. Diana organiseert daarom fuiven, facavonden, cantussen, sportactiviteiten, filmavonden, cultuuractiviteiten en nog zoveel meer!
                    We zijn constant in de weer met het organiseren van ontspannende evenementen en deze pagina zal ook geüpdate worden voor elk nieuw evenement. Kom dus zeker af en toe nog eens kijken!
                    Al onze evenementen zijn tevens terug te vinden op onze facebook pagina met meer info.
                    Zijn er momenteel geen evenementen en lijkt het wat leeg?
                    Geen zorgen, enkel toekomstige activiteiten worden op deze pagina getoond. We verzekeren je dat hier binnenkort weer meer informatie terug te vinden is!
                    Deze zullen zo snel mogelijk online gezet worden waarna jij je weer direct kan inschrijven.
                </p>
                <p className={isMobile? "Typography-white Typography-alinea Section2-eventText2-mobile": "Typography-white Typography-alinea Section2-eventText2"}>
                    Heb je een leuk idee of een vraag over een activiteit? Laat het ons gerust weten via de contact pagina!
                </p>
            </div>

        </section>
    )
}
export default Section2;