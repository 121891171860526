import * as React from "react";
import {useRef} from "react";
import {motion, useCycle, useViewportScroll} from "framer-motion";
import {UseDimensions} from "../UseDimensions";
import Navigation from "./Navigation";
import MenuToggle from "./MenuToggle";


const topbar = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at calc(100% - 40px) 50px)`,
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2
        }
    }),
    closed: {
        clipPath: "circle(30px at calc(100% - 40px) 50px)",
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};


const HamburgerMenu = () => {
    const [isOpen, toggleOpen] = useCycle(true, false);
    const containerRef = useRef(null);
    const {height} = UseDimensions(containerRef);
    const { scrollY } = useViewportScroll();
    /** this hook manages state **/
    const [hidden, setHidden] = React.useState(false);

    function toggle() {
        if (hidden){
            setHidden(false);
        } else {
            setHidden(true)
        }
    }
    /** this onUpdate function will be called in the `scrollY.onChange` callback **/
    function update() {
        // @ts-ignore
        if (scrollY?.current < scrollY?.prev) {
            // setHidden(false);
        } else { // @ts-ignore
            if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
                        setHidden(true);
                    }
        }
    }

    /** update the onChange callback to call for `update()` **/
    React.useEffect(() => {
        return scrollY.onChange(() => update());
    });

    React.useEffect(() => {
        if (isOpen && hidden){
            toggleOpen()
        }
        else if  (!isOpen && !hidden){
            toggleOpen()
        }
    }, [hidden, isOpen, toggleOpen]);

    return (

        <div className={"HamburgerMenu-root"}>
            <motion.nav
                initial={"closed"}
                animate={isOpen ? "open" : "closed"}
                custom={height}
                ref={containerRef}
            >
                <motion.div className={"HamburgerMenu-background"} variants={topbar}/>
                <Navigation/>
                <MenuToggle toggle={() => toggle()}/>
            </motion.nav>
        </div>


    );
};
export default HamburgerMenu;