import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import {default as data} from "../MenuData.json";

const variants = {
    open: {
        zIndex: 5,
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
        zIndex: -2,
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};

const Navigation = () => {

    return (
        <motion.ul variants={variants} className={"Navigation-list"}>
            {data.map((menuItem) => {
                return (<MenuItem key={menuItem.name} name={menuItem.name} subMenus={menuItem.subMenus} route={menuItem.route}/>)
            })}
        </motion.ul>
    )
}
export default Navigation;
