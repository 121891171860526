import {motion, useAnimation} from "framer-motion";
import Menu from "../components/menu/Menu";
import ImageRightAnimation from "../components/animations/ImageRightAnimation";
import React, {useEffect} from "react";
import {useMediaQuery} from "@material-ui/core";
import theme from "../theme";
import {useInView} from "react-intersection-observer";
import Footer from "../components/footer/Footer";

const ContactPage = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView])

    return (
        <div className={"Page-root"}>
            <motion.section exit={{opacity: 0}}>
                <Menu/>
                <section className={isMobile? "ContactPage-section1-mobile" : "ContactPage-section1"}>
                    <div className={isMobile? "ContactPage-textBox-left-mobile" : "ContactPage-textBox-left"}>
                        <h1 className={"Typography-red"}>Contact</h1>

                        <p className={"Typography-red"}>
                            Heeft u vragen of opmerkingen omtrent studentenvereniging Diana of één van onze activiteiten,
                            of vertegenwoordigt u een bedrijf en heeft u misschien een interessant voorstel?
                            Wij staan altijd open voor welke vraag dan ook en beantwoorden deze met plezier in een oriënterend gesprek.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Indien u ons per klassieke brievenpost wenst te contacteren kan dit via bijgevoegd postadres.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Graag willen we wel even melden dat het departement Gezondheidszorg en Chemie enkele weken sluit in juli en augustus,
                            zodat klassieke postbezorging bemoeilijkt wordt tijdens deze maanden.
                            Wenst u ons tijdens de zomer te contacteren, dan kan dit best elektronisch.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Teneinde een snel antwoord te garanderen vragen we u echter om een e-mail te sturen.
                            Hierdoor zijn we in staat om uw vraag sneller bij het bevoegde praesidiumlid te bezorgen
                            om een zo nauwkeurig mogelijk antwoord te kunnen verzorgen in een zo kort mogelijke tijdsspanne.
                        </p>
                    </div>
                    <div className={isMobile? "ContactPage-imageBox-right-mobile" : "ContactPage-imageBox-right"}>
                        <motion.div
                            ref={ref}
                            animate={controls}
                            initial="hidden"
                            variants={ImageRightAnimation}
                            className={isMobile? "ContactPage-contactBox-mobile" : "ContactPage-contactBox"}>

                            <p className={"Typography-red Typography-bold"}>
                                Postadres:
                            </p>
                            <p className={"Typography-red"}>
                                Studentenvereniging Diana
                            </p>
                            <p className={"Typography-red"}>
                                Departement Gezondheidszorg en Chemie
                            </p>
                            <p className={"Typography-red"}>
                                Herestraat 49
                            </p>
                            <p className={"Typography-red "}>
                                3000 Leuven
                            </p>

                            <p className={"Typography-red Typography-bold Typography-alinea"}>
                                E-mailadres:
                            </p>
                            <p className={"Typography-red"}>
                                algemeen@diana.be
                            </p>


                        </motion.div>
                    </div>
                </section>
            </motion.section>
            <Footer color={"primary"}/>
        </div>
    )
}
export default ContactPage;