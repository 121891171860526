import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import React from "react";
import {AnimatePresence} from "framer-motion";
import KringliedPage from "./pages/KringliedPage";
import Geschiedenispage from "./pages/GeschiedenisPage";
import ContactPage from "./pages/ContactPage";
import StatutenPage from "./pages/StatutenPage";
import PraesidiaPage from "./pages/PraesidiaPage";
import AluminuPage from "./pages/AluminuPage";
import Sponsers from "./pages/Sponsers";
import ScrollToTop from "./components/ScrollToTop";
import GDPRPage from "./pages/GDPRPage";
import InternReglementPage from "./pages/InternReglementPage";
import {createBrowserHistory} from "history";
import ReactGA from 'react-ga';

export interface Props {
    isFirstMount: boolean;
}

const Routes = ({isFirstMount}: Props) => {
    const location = useLocation();

    const history = createBrowserHistory();

    // used for Google Analytics tracking
    history.listen((location) => {
            ReactGA.initialize('UA-144042472-3');
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname)
        }
    );

    //All the routes for pages
    return (
        <AnimatePresence exitBeforeEnter>
            <ScrollToTop />

            <Switch location={location} key={location.pathname}>
                {/*routes reachable for everyone*/}
                <Route path="/" exact
                       component={(props: any) => (
                           <HomePage isFirstMount={isFirstMount}/>
                       )}
                />
                <Route path="/home"
                       component={(props: any) => (
                           <HomePage isFirstMount={isFirstMount}/>
                       )}
                />
               

                <Route path={"/praesidium"}
                       component={(props: any) => (<PraesidiaPage/>)}
                />
                   <Route path={"/alumia/:year"}
                       component={(props: any) => (<AluminuPage props={props}  />)}
                />
           
                
                <Route path={"/kringlied"}
                       component={(props: any) => (<KringliedPage/>)}
                />

                     <Route path={"/sponsers"}
                       component={(props: any) => (<Sponsers/>)}
                />

                <Route path={"/geschiedenis"}
                       component={(props: any) => (<Geschiedenispage/>)}
                />
                <Route path={"/statuten"}
                       component={(props: any) => (<StatutenPage/>)}
                />
                <Route path={"/intern-reglement"}
                       component={(props: any) => (<InternReglementPage/>)}
                />
                <Route path={"/gdpr"}
                       component={(props: any) => (<GDPRPage/>)}
                />
                <Route path={"/examenwiki"}
                       component={() => {
                           var anchor = document.createElement('a');
                           anchor.href = 'https://examenwiki.diana.be/';
                           anchor.click();
                           return  null
                       }}
                />
                <Route path={"/examenrooster"}
                       component={() => {
                           var anchor = document.createElement('a');
                           anchor.href = 'https://examenrooster.diana.be/';
                           anchor.click();
                           return  null;
                       }}
                />
                <Route path={"/lidkaarten"}
                       component={() => {
                           var anchor = document.createElement('a');
                           anchor.href = 'https://lidkaarten.diana.be/';
                           anchor.target="_blank";
                           anchor.click();
                           return  <Redirect to="/"/>
                       }}
                />
                <Route path={"/contact"}
                       component={(props: any) => (<ContactPage/>)}
                />

                {/*default if no match with previous routes.*/}
                <Route path={"*"} component={(props:any) => <Redirect to={"/home"}/>}/>
            </Switch>
        </AnimatePresence>
    );
};

export default Routes;
