import {useEffect, useState} from "react";
import {Facebook, Instagram} from "@material-ui/icons";
import {SvgIcon} from "@material-ui/core";

interface Props{
    color: string;
}

//This is the footer used at the bottom of the page. Give it either 'black', 'primary' (red), 'white' or 'paper' as parameter to define the styling.
const Footer = ({color}: Props ) => {
    var [finalColor, setFinalColor] = useState("");
    var [date, setDate] = useState(0);

    useEffect(() => {
        if (color === "black"){
            setFinalColor("Footer-black")
        }
        if (color === "primary"){
            setFinalColor("Footer-primary")
        }
        if (color === "white"){
            setFinalColor("Footer-white")
        }
        if (color === "paper"){
            setFinalColor("Footer-paper")
        }
        setDate(new Date().getFullYear());
    },[color])

    const facebookClicked = () => {
        window.open( "https://www.facebook.com/dianaleuven", '_blank');
    };

    const instagramClicked = () => {
        window.open( "https://www.instagram.com/dianaleuven/", '_blank');
    };

    const discordClicked = () => {
        window.open( "https://discord.gg/FemX4qgQaf", '_blank');
    };


    return(
        <div className={"Footer-root " + finalColor}>
            <div className={"Footer-socialBox"}>
                <Instagram className={"Footer-social " + finalColor} onClick={instagramClicked} />
                <Facebook className={"Footer-social " + finalColor} onClick={facebookClicked} />
                <SvgIcon className={"Footer-social " + finalColor} onClick={discordClicked}>
                    <path d="M9.07 4.36c-.42.06-.93.17-1.35.3-.95.29-1.91.76-2.74 1.35-.16.12-.17.12-.26.27-.23.37-.54.97-.76 1.5-.99 2.28-1.61 4.99-1.82 7.92l-.02.32.05.06c.03.04.16.17.3.31.52.52 1.08.94 1.75 1.3.88.47 1.94.81 3.01.97.2.03.56.06.66.06h.08l.36-.48c.2-.27.36-.49.36-.49s-.03-.02-.07-.03c-.15-.05-.64-.25-.86-.36-1.13-.54-2-1.19-2.88-2.16-.06-.06-.05-.06.15.08 1.69 1.23 3.68 1.92 6.05 2.07.37.02 1.5.02 1.86 0 2.37-.15 4.36-.83 6.05-2.07.24-.17.24-.19-.07.15-.61.65-1.43 1.28-2.26 1.72-.36.19-.99.47-1.27.56-.04.01-.07.03-.07.03 0 .01.16.23.36.49l.36.48h.08c.27 0 .92-.09 1.4-.2 1.59-.35 2.98-1.09 4.02-2.13.13-.13.27-.27.3-.31l.05-.06-.02-.32c-.23-3.21-.96-6.19-2.1-8.52-.18-.37-.27-.53-.45-.84l-.12-.2-.19-.13c-1.26-.88-2.77-1.49-4.07-1.64-.1-.01-.2-.02-.21-.02-.03 0-.05.03-.14.22-.07.15-.1.22-.09.22.01 0 .1.03.21.05 1.26.33 2.27.79 3.13 1.44.21.16.68.55.9.75 0 0-.19-.09-.44-.21-1.26-.63-2.12-.96-3.06-1.2-1.02-.25-2.02-.37-3.23-.37-1.59 0-2.84.2-4.19.66-.62.21-1.19.46-2.1.91-.38.19-.44.21-.38.17.81-.71 1.41-1.12 2.15-1.49.6-.29 1.23-.52 1.86-.67.08-.02.16-.04.17-.04.01 0-.02-.07-.09-.22-.14-.25-.11-.23-.36-.2zm.25 6.29c.75.12 1.33.9 1.33 1.78 0 1.02-.75 1.84-1.64 1.79-.71-.04-1.29-.58-1.47-1.38-.04-.18-.04-.62-.01-.8.06-.26.16-.52.29-.71.37-.52.92-.77 1.5-.68zm5.88.01c.62.13 1.11.66 1.26 1.38.04.17.03.62-.01.8-.09.39-.25.69-.5.94-.6.6-1.49.59-2.07-.01-.7-.72-.71-1.94-.02-2.66.36-.39.85-.55 1.34-.45z"/>
                </SvgIcon>
            </div>
            <p className={"Footer-copyright " + finalColor}> Copyright &copy; {date} Diana Leuven. Alle rechten voorbehouden.</p>
        </div>
    )
}
export default Footer