import ReactTextTransition, {presets} from "react-text-transition";
import React, {useEffect, useState} from "react";
import {useMediaQuery} from "@material-ui/core";
import theme from "../../theme";

//Section 1 of the homepage is the 'richtingen' animated with the background image and diana logo
const Section1 = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [textIndex, setTextIndex] = useState(0);
    const richtingen = [
        {label: "Toegepaste informatica", timeout: 1500},
        {label: "Chemie", timeout: 1500},
        {label: "Biomedische laboratoriumtechnologie", timeout: 1500},
        {label: "voedings- en dieetkunde", timeout: 1500},
        {label: "graduaat biotechnologie", timeout: 1500},
        {label: "graduaat elektromechanische systemen", timeout: 1500},
        {label: "graduaat internet of things", timeout: 1500},
        {label: "graduaat programmeren", timeout: 1500},
        {label: "graduaat systeem- en netwerkbeheer", timeout: 1500},
        {label: "Iedereen", timeout: 3000}
    ]


    useEffect(() => {
        const timeout = setTimeout( () =>  {
            var nextIndex = (textIndex + 1) % richtingen.length;
            setTextIndex(nextIndex);
        }, richtingen[textIndex].timeout);
        return () => clearTimeout(timeout);
    },);

    return(
        <section className="Section1-root">
            <div className={"Section1-flexbox"}>
                <div>
                    <p className={isMobile? "Section1-text-mobile" : "Section1-text"}>Diana: de vereniging voor...</p>

                    <ReactTextTransition
                        text={richtingen[textIndex].label}
                        springConfig={presets.gentle}
                        style={{margin: "0 4px"}}
                        inline
                        direction={"down"}
                        className={isMobile? "Section1-transition-mobile" : "Section1-transition"}
                        noOverflow={isMobile? false : true}
                    />
                </div>
                <img alt={"Diana schild"} className={isMobile ? "Section1-schild-mobile" : "Section1-schild"} src={"https://images.diana.be/diana_website/Schild_Vector_Red.svg"}/>
            </div>
        </section>
    )
}
export default Section1;